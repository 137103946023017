<template>
<div>
    <navigation-bar />
    <not-found-component />
    <footer-component />
</div>
</template>

<script>
import NotFoundComponent from '../components/NotFoundComponent.vue';
import NavigationBar from '../components/NavigationBar.vue';
import Footer from '../components/Footer';

export default {
  name: 'NotFound',
  components: {
    'not-found-component': NotFoundComponent,
    'navigation-bar': NavigationBar,
    'footer-component': Footer,
    },
};
</script>
