<template>
  <div class="event-info event-content" style="overflow-y:scroll;">
    <!-- event info -->
    <div style="margin-top: 2em; margin-right: 2em">
      <section>
        <h1 class="is-size-4">Response event information</h1>
        <br />
        <br />

        <h2 class="is-size-4">
          {{ currentEvent.event_name }}
        </h2>
        <br />
        <h3 class="is-size-5">
          {{ currentEvent.description }}
        </h3>
        <br />
        <h3 class="is-size-5">
          <i> {{ currentEvent.trigger_name }} </i>
        </h3>
        <br />

        <div class="tags">
          <span
            class="tag is-size-6"
            v-for="tag in tags"
            :key="tags.indexOf(tag)"
            @click="searchTag(tag.id)"
          >
            {{ tag.content }}
          </span>
        </div>

        <br />
      </section>

      <br />

      <!-- Image response -->
      <div v-if="currentEvent.event_type == 'image'">
        <img style="display:block; margin:10 auto; " :src="currentEvent.src" />
      </div>

      <!-- Pdf response -->
      <div v-if="currentEvent.event_type == 'pdf'">
        <p style="display:block; margin: 0 auto;">
          <a class="is-size-5" :href="currentEvent.src" target="_blank"
            >see it in a new tab.</a
          >
        </p>
      </div>

      <!-- Text response -->
      <div v-if="currentEvent.event_type == 'text'">
        <br />
        <p class="is-size-5">
          {{ currentEvent.content }}
        </p>
      </div>

      <!-- Tweet response -->
      <div v-if="currentEvent.event_type == 'tweet'">
        <tweet-card
          :id="currentEvent.external_id"
          :key="currentEvent.external_id"
        >
        </tweet-card>
      </div>

      <!-- Youtube comment response -->
      <div v-if="currentEvent.event_type == 'youtube-comment'">
        <div class="iframe-container">
          <iframe
            :src="'https://www.youtube.com/embed/' + currentEvent.src"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
          </iframe>
        </div>
        <br />

        <youtube-comment-item :currentEvent="currentEvent" />
      </div>

      <!-- Video response -->
      <div
        ref="videoDiv"
        v-if="currentEvent.event_type == 'video'"
        :key="currentEvent.id"
      >
        <video style="display:block; margin: 0 auto;" controls width="90%">
          <source :src="currentEvent.src" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>

      <!-- Youtube video response -->
      <div v-if="currentEvent.event_type == 'youtube'">
        <div class="iframe-container">
          <iframe
            :src="'https://www.youtube.com/embed/' + currentEvent.src"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
          </iframe>
        </div>
      </div>
    </div>

    <event-edit v-if="this.isActive" />

    <div
      v-if="user.user_role !== 'watcher'"
      style="margin-top: 2em; margin-right: 2em;
      "
    >
      <div class="buttons are-medium">
        <button
          class="button is-outlined"
          style="width: 110px;"
          @click="openEditModal"
        >
          Edit
        </button>
        <button
          class="button"
          style="width: 110px; color:#E74C3C;"
          @click="switchDeleteModalActiveness"
        >
          Delete
        </button>
      </div>
    </div>
    <br />
    <br />

    <!-- COMMENTS COMPONENT  -->
    <comments
      :comments="comments"
      :parent_id="currentEvent.id"
      :isTriggerComment="false"
      v-on:newComment="requestComments()"
      :key="currentEvent.id"
      v-if="user.user_role !== 'watcher'"
    />


      <!-- DELETE MODAL -->
      <div class="modal" v-bind:class="{ 'is-active': isDeleteModalActive }">
  <div class="modal-background" style="background-color: rgb(231, 76, 60, 0.8);"
  @click="switchDeleteModalActiveness"></div>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title">Delete Response Event</p>
      <button class="delete" aria-label="close"
      @click="switchDeleteModalActiveness"></button>
    </header>
    <section class="modal-card-body">
      <!-- Content ... -->
      <p>
        Are you sure you want to delete this response event <br> 
        <em> {{currentEvent.event_name}} </em> ?
        </p>
    </section>
    <footer class="modal-card-foot">
      <div class="buttons" v-if="!deleteFailure && !deleteSuccess">
      <button class="button" @click="deleteEvent">Yes</button>
      <button class="button" @click="switchDeleteModalActiveness">Cancel</button>
      </div>
      <div v-if="deleteSuccess" class="notification is-success"
        style="color: #F4F6F6;
        background-color:#52BE80;">
          <button class="delete" @click="success = false"></button>
          The response event has been deleted. The page will be refreshed
          now.
        </div>
        <div v-if="deleteFailure" class="notification is-danger"
        style="color: #F4F6F6;
        background-color:#E74C3C;">
          <button class="delete" @click="failure = false"></button>
          The response event could not be deleted. Please try again later or
          <a href="#">report an issue</a>.
        </div>
    </footer>
  </div>
</div>
  </div>
</template>

<script>
import EventEditComponent from "./EventEditComponent.vue";
import axios from "axios";
import { Tweet } from "vue-tweet-embed";
import YoutubeCommentItem from "./YoutubeCommentItem";
import Comments from "./Comments";

export default {
  name: "EventInfo",
  watch: {
    currentEvent: function(oldVal, newVal) {
      this.requestComments();
    }
  },
  data() {
    return {
      isDeleteModalActive: false,
      deleteSuccess: false,
      deleteFailure: false
    };
  },
  components: {
    "event-edit": EventEditComponent,
    "tweet-card": Tweet,
    "youtube-comment-item": YoutubeCommentItem,
    comments: Comments
  },
  computed: {
    currentEvent: function() {
      return this.$store.getters.getCurrentEvent;
    },
    tags: function() {
      return this.$store.getters.getCurrentTags;
    },
    isActive: function() {
      return this.$store.getters.getEventEditModal;
    },
    user: function() {
      return this.$store.getters.getUser;
    },
    comments: function() {
      return this.$store.getters.getResponseComments;
    }
  },
  methods: {
    videoWidth: function() {
      let videoDiv = this.$refs.videoDiv;
      return videoDiv.offsetWidth;
    },
    openEditModal: function() {
      this.$store.dispatch("changeEvenEditModal", true);
    },
    getLocaleDate(dateString) {
      let timestamp = new Date(dateString);
      return timestamp.toLocaleDateString("en-CA");
    },
    switchDeleteModalActiveness() {
      this.isDeleteModalActive = !this.isDeleteModalActive;
    },
    deleteEvent() {
      let eventId = this.currentEvent.id;
      let deleteUrl = "/delete/" + eventId;
      axios({
        method: "post",
        url: deleteUrl
      })
        .then(() => {
          this.deleteSuccess = true;
          setTimeout(() => {
            this.$router.go();
          }, 1000);
        })
        .catch(() => {
          this.deleteFailure = true;
          setTimeout(() => {
            this.switchDeleteModalActiveness();
          }, 1000);
          this.deleteFailure = false;
        });
    },
    /** Downloads the given video from server */
    downloadVideo(fileDir) {
      // first option
      // window.open(`http://localhost:8000${fileDir}`, '_blank', 'download');

      // second option
      // const fileName = fileDir.split('/').pop();
      // const downloadUrl = `/download/videos/${fileName}`;
      // console.log('=== download url', downloadUrl);
      // axios({
      //   method: "get",
      //   url: downloadUrl,
      //   responseType: 'blob',
      // })
      // .then((response)=> {
      //   console.log('=== got respone', response);
      //   // create file link in browser's memory
      //   const href = URL.createObjectURL(response.data);

      //   // create "a" element with href to file & click
      //   const link = document.createElement('a');
      //   link.href = href;
      //   link.setAttribute('download', 'emodissey_video.mp4');
      //   document.body.appendChild(link);
      //   console.log('=== will click the link');
      //   link.click();

      //   document.body.removeChild(link);
      //   URL.revokeObjectURL(href);
      // })
      // .catch((err) => {
      //   console.log('=== download err', JSON.stringify(err));
      // })

      // third option
      const link = document.createElement('a');
      const fileName = fileDir.split('/').pop();
      link.href =`http://localhost:8000/download/videos/${fileName}`;
      link.setAttribute('download', 'emodissey-video.mp4');
      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    },
    requestComments() {
      this.$store.dispatch("resetResponseComments");
      this.$store.dispatch("requestResponseComments", this.currentEvent.id);
    },
    searchTag(id){
      let path = "/events/triggers=&types=&tags=" + id + "&from=&to=";
      this.$router.push(path);
    }
  },
  created() {
      this.requestComments();
  }
};
</script>

<style scoped>
.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
}

.iframe-container iframe {
  border: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

/* 4x3 Aspect Ratio */
.iframe-container-4x3 {
  padding-top: 75%;
}


.event-info{
  padding-bottom: 5em;
}

.tag:hover{
  cursor: pointer;
}
</style>
