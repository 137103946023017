<template>
    <div class="tags-component">
        <vue-tags-input :tags="selectedTags" v-model="tag"
       :autocomplete-items="filteredItems"
       :add-only-from-autocomplete="true" 
       :autocomplete-always-open="true"
       @tags-changed="updateTagIds"/>
    </div>
</template>

<script>
import VueTagsInput from '@johmun/vue-tags-input';

export default {
    name: 'TagInput',
    components: {
    'vue-tags-input': VueTagsInput,
  },
  props: {
      /* list of tags to pick from */
      tags: Array,
      /* an array of ids of the previously selected tags */
      selected: {
        type: Array,
        default: () => {
          return [];
        }
      }
  },
  computed: {
      filteredItems(){
        return this.tagObjectArray.filter(i => {
            return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });
      },
      tagObjectArray(){
          return this.tags.map(tag => {
              return {
                  text: tag.content,
                  id: tag.id,
              }
          })
      },
      selectedTags(){
        if(this.currentlySelected.length > 0){
          return this.currentlySelected;
        } else if (this.currentlySelected < 1 && this.selected.length > 0){
          return this.getPreviouslySelected();
        } else {
          return [];
        }
      }
  },
  data() {
      return {
          tag: "",
          selectedTagIds: [],
          currentlySelected: [],
          openAutoComplete: false,
      }
  },
  methods: {
      updateTagIds(newTags){
          this.currentlySelected = newTags;
          this.selectedTagIds = newTags.map(tag => {
              return tag.id;
          });
        this.$emit('tags-changed', this.selectedTagIds);
      },
      getPreviouslySelected(){
        return this.tagObjectArray.filter(tag => 
        this.selected.includes(tag.id)
        );
      }
  }
}
</script>

<style>
.vue-tags-input {
    background: transparent;
  }

  
  /* some stylings for the autocomplete layer */
  .vue-tags-input .ti-autocomplete {
    background: whitesmoke;
    border: 1px solid gainsboro;
    border-top: none;
    height: 160px;
    overflow: scroll;
  }

  /* the selected item in the autocomplete layer, should be highlighted */
  .vue-tags-input .ti-item.ti-selected-item {
    background: #ffd754;
    color: black;
  }

  /* default styles for all the tags */
  .vue-tags-input .ti-tag {
    position: relative;
    background: whitesmoke;
    color: black;
  }

  .vue-tags-input .ti-tag:hover{
    position: relative;
    background: gainsboro;
  }

  .vue-tags-input {
    background: #324652;
  }

  .tags-component {
    margin-bottom: 160px;
  }
</style>