<template>
  <div>
    <div class="section" >
      <h1 class="is-size-4" style="margin-bottom: 1em;"> Upload </h1>
      <div class="link-fields">
      <div class="columns is-multiline">
        <div class="column is-half">
          <div class="box link-box" @click="$router.push('/upload/trigger')">
              <p class="has-text-dark is-size-4">Trigger Event</p>
          </div>
        
        </div>

        <div class="column is-half">
          <div class="box link-box"  @click="$router.push('/upload/response')">
              <p class="has-text-dark is-size-4">Response Event</p>
          </div>
        
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadMain",
  methods: {
      
  }
};
</script>


<style scoped>
  .link-box{
    max-width:40em;
    text-align:center; vertical-align:center;
    cursor: pointer;
  }
  .link-box:hover{
    background-color: #F4F6F6;
  }
  .link-fields{
  width: 80%;
  margin-left:  5%;
  margin-right: 5%;
  padding: 5%;
}
</style>
