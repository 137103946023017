<template>
  <div>
    <navigation-bar />
    <div class="section"> 
      <h1 class="is-size-4"> Upload a Response Event  >
           </h1>
       <h1 class="is-size-4"> Get & Upload Youtube Comments
           </h1>
   </div>
   <upload-youtube-comment />
   <footer-component />
  </div>
</template>

<script>

import NavigationBar from "../../components/NavigationBar.vue";
import UploadResponseYoutubeComment from "../../components/Response/UploadResponseYoutubeComment";
import Footer from '../../components/Footer';


export default {
  name: "UploadResponseYoutubeComment",
  components: {
    "navigation-bar": NavigationBar,
    "upload-youtube-comment": UploadResponseYoutubeComment,
    'footer-component': Footer,
  }
};
</script>
