<template>
  <div>
    <navigation-bar />
    <div class="section"> 
      <h1 class="is-size-4"> Upload a Response Event  >
           </h1>
       <h1 class="is-size-4"> Upload a Youtube Video via Url
           </h1>
   </div>
   <upload-youtube />
   <footer-component />
  </div>
</template>

<script>

import NavigationBar from "../../components/NavigationBar.vue";
import UploadYoutube from "../../components/Response/UploadYoutube";
import Footer from '../../components/Footer';


export default {
  name: "UploadResponseYoutube",
  components: {
    "navigation-bar": NavigationBar,
    "upload-youtube": UploadYoutube,
    'footer-component': Footer,
  }
};
</script>
