<template>
  <div>
    <navigation-bar />
    <search-form />
    <footer-component />
  </div>
</template>

<script>
import NavigationBar from "../components/NavigationBar.vue";
import SearchForm from "../components/SearchForm.vue";
import Footer from '../components/Footer';



export default {
  name: "Search",
  components: {
    "navigation-bar": NavigationBar,
    "search-form": SearchForm,
    'footer-component': Footer,
  }
};
</script>

