<template>
    
        <aside class="menu is-medium">
            <p class="menu-label">
                {{title}}
            </p>
            <ul class="menu-list">
                <li v-for="link in links" :key="link.title">
                    <router-link :to="path + link.relative_path" :style="activeLink(link.relative_path)">
                        {{link.title}}
                    </router-link>
                </li>
            </ul>
            
        </aside>
    
</template>

<script>
export default {
    name: "MenuComponent",
    props: {
        path: {
            type: String,
        },
        title: {
            type: String,
            default: () => {
                return "MENU";
            }
        },

        /**
         * Must consist of link objects:
         *  title: title for the linked component
         *  relative_path: path that starts from the main page that contains the menu
         * 
         */
        links: {
            type: Array,
            default: () => {
            return []
            }
        },
    },
    methods: {
        activeLink(path){
            let activeLink = "color: white; background-color: #AEB6BF;"
            let currentPath = this.$route.path.split("/").pop();
            if(currentPath === path) return activeLink;
            return "";
        }
    }
}
</script>

