<template>
  <div class="modal is-active">
    <div class="modal-background"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Event Edit</p>

        <button class="delete" aria-label="close" @click="closeModal"></button>
      </header>
      <section class="modal-card-body">
        <!-- Content ... -->
        <div>
          <div class="section">
            <!-- Name -->
            <div class="field is-size-5">
              <label class="label is-size-5">Event name</label>
              <textarea
                class="textarea is-size-5"
                id="event_name"
                :placeholder="event.name"
                v-model="event_name"
                maxlength="500"
              ></textarea>
              <!-- no name warning -->
              <p v-if="errorObject.name" class="help is-danger">
                {{ errorObject.name }}
              </p>
            </div>

            <!-- Description -->
            <div class="field">
              <label class="label is-size-5">Event description</label>

              <textarea
                class="textarea is-size-5"
                :placeholder="event.description"
                v-model="eventDescription"
                maxlength="1000"
              ></textarea>

              <!-- no description warning -->
              <p v-if="errorObject.description" class="help is-danger">
                {{ errorObject.description }}
              </p>
            </div>
            <br />

            <!-- Trigger Input -->
            <div class="field">
              <label class="label is-size-5">Select a trigger event</label>
              <div class="control">
                <div class="select is-size-5">
                  <select v-model="selectedTrigger">
                    <option
                      v-for="trigger in triggerEvents"
                      :key="trigger.id"
                      :id="trigger.id"
                      :value="trigger.id"
                    >
                      {{ trigger.event_name }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- no trigger warning -->
              <p v-if="errorObject.trigger" class="help is-danger">
                {{ errorObject.trigger }}
              </p>
            </div>
            <br />

            <!-- Tags Input -->
            <!-- New Tags Input -->
            <div class="field is-size-5">
              <label class="label is-size-5">select tags</label>
              <tags-input
                :tags="tags"
                @tags-changed="updateSelectedTagIds"
                :selected="checkedTags"
              />
            </div>
            <br />

            <!-- Date Input -->
            <div class="field">
              <label class="label is-size-5">Enter date</label>
              <functional-calendar
                class="date-picker"
                :is-date-picker="true"
                :change-month-function="true"
                :change-year-function="true"
                :dateFormat="'yyyy/mm/dd'"
                v-on:dayClicked="changeDate"
              >
              </functional-calendar>
              <!-- no date warning -->
              <p v-if="errorObject.eventDate" class="help is-danger">
                {{ errorObject.eventDate }}
              </p>
            </div>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <div class="buttons" v-if="!success && !failure">
          <button class="button" @click="upload">Save</button>
          <button class="button" @click="closeModal">Cancel</button>
          <button class="button" @click="resetFields">Reset fields</button>
        </div>
        <div
          v-if="success"
          class="notification is-success"
          style="color: #F4F6F6;
        background-color:#52BE80;"
        >
          <button class="delete" @click="success = false"></button>
          The response event is updated successfully. The page will be refreshed
          now.
        </div>
        <div
          v-if="failure"
          class="notification is-danger"
          style="color: #F4F6F6;
        background-color:#E74C3C;"
        >
          <button class="delete" @click="failure = false"></button>
          The response event could not be updated. Please try again later or
          <a href="#">report an issue</a>.
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import axios from "axios";
axios.defaults.maxContentLength = Infinity;
import TagsInput from "./TagsInput";
import { FunctionalCalendar } from "vue-functional-calendar";

export default {
  name: "EventEditComponent",
  components: {
    "tags-input": TagsInput,
    "functional-calendar": FunctionalCalendar,
  },
  data() {
    return {
      selectedTrigger: "",
      checkedTags: [],
      eventDate: "",
      eventDescription: "",
      event_name: "",
      sending: false,
      errorObject: {
        trigger: "",
        tags: "",
        eventDate: "",
        description: "",
        name: "",
      },
      success: false,
      failure: false,
    };
  },
  created() {
    this.$store.dispatch("requestTriggerEvents");
    this.$store.dispatch("requestTags");
    this.resetFields();
  },
  computed: {
    triggerEvents: function() {
      return this.$store.getters.getTriggerEvents;
    },
    tags: function() {
      return this.$store.getters.getTags;
    },
    currentTags: function() {
      return this.$store.getters.getCurrentTags.map((item) => item.id);
    },
    event: function() {
      return this.$store.getters.getCurrentEvent;
    },
  },
  methods: {
    // reset all fields to the current event data
    resetFields() {
      this.checkedTags = this.$store.getters.getCurrentTags.map(
        (item) => item.id
      );
      let currentEvent = this.$store.getters.getCurrentEvent;
      this.event_name = currentEvent.event_name;
      this.selectedTrigger = currentEvent.trigger_id;
      this.eventDescription = currentEvent.description;
    },

    // closes the edit modal
    closeModal() {
      this.$store.dispatch("changeEvenEditModal", false);
    },

    // formats date to yyyy-mm-dd
    formatDate(dateString) {
      let timestamp = new Date(dateString);
      return timestamp.toLocaleDateString("en-CA");
    },

    /**
     * Checks if all the required fields are completed for file upload
     * fields:
     * file, selectedTrigger, checkedTags, eventDate, eventDescription, event_name, selectedType
     */
    checkErrors() {
      // selectedTrigger
      if (this.selectedTrigger.length < 1) {
        this.errorObject.trigger = "Please pick a corresponding trigger event.";
      } else {
        this.errorObject.trigger = "";
      }

      // eventDate
      if (this.eventDate.length < 1) {
        this.errorObject.eventDate = "Please pick a date.";
      } else {
        this.errorObject.eventDate = "";
      }

      // eventDescription
      if (this.eventDescription.length < 1) {
        this.errorObject.description =
          "Please provide a description for the event.";
      } else {
        this.errorObject.description = "";
      }

      // event_name
      if (this.event_name.length < 1) {
        this.errorObject.name = "Please provide a name for the event.";
      } else {
        this.errorObject.name = "";
      }
    },

    /**
     * Checks if there is any error with form info by checking the error object.
     * If no errors returns true; false otherwise.
     */
    requiredFileInfoGiven() {
      return (
        this.errorObject.name.length === 0 &&
        this.errorObject.description.length === 0 &&
        this.errorObject.trigger.length === 0 &&
        this.errorObject.eventDate.length === 0
      );
    },

    /**
     * Builds and returns a form data by appending file
     * and file metadata to a FormData object.
     */
    getFormData() {
      let formData = new FormData();
      formData.append("id", this.event.id);
      formData.append("name", this.event_name);
      formData.append("description", this.eventDescription);
      formData.append("trigger", this.selectedTrigger);
      formData.append("triggerName", this.getTriggerName(this.selectedTrigger));
      formData.append("eventDate", this.eventDate);
      formData.append("tags", this.checkedTags);
      return formData;
    },

    // sends the file and file information to the backend
    upload() {
      this.checkErrors();
      if (this.requiredFileInfoGiven()) {
        // build formData
        let formData = this.getFormData();

        // change appropriate fields to signal sending event
        this.sending = true;
        this.success = false;
        this.failure = false;

        // send file and file metadata
        axios({
          method: "post",
          url: "/update",
          data: formData,
          headers: {
            "content-type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        })
          .then(() => {
            this.sending = false;
            this.success = true;
            // refresh the page
            setTimeout(() => {
              this.$router.go();
            }, 2000);
          })
          .catch(() => {
            this.sending = false;
            this.failure = true;
            // TODO handle upload failure
          });
      }
    },

    // returns the name of the selected trigger event given its id
    getTriggerName(id) {
      let triggerName = this.triggerEvents.find((trigger) => trigger.id === id);
      return triggerName.event_name;
    },

    updateSelectedTagIds(idArray) {
      this.checkedTags = idArray;
    },

    // updates the selected event date based on date-picker event
    changeDate(newDate) {
      this.eventDate = newDate.date.split("/").join("-");
    },
  },
};
</script>

<style scoped>

</style>
