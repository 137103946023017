<template>
  <div>
    <navigation-bar />
    <upload-response />
    <footer-component />
  </div>
</template>

<script>
import UploadResponse from "../components/UploadResponse.vue";
import NavigationBar from "../components/NavigationBar.vue";
import Footer from '../components/Footer';


export default {
  name: "UploadResponse",
  components: {
    "upload-response": UploadResponse,
    "navigation-bar": NavigationBar,
    'footer-component': Footer,
  }
};
</script>
