<template>
  <div>
    <navigation-bar />

    
    
      <!-- Menu Column -->
      <div class="columns" >
        <div class="column is-3" style="border-right: 1px solid black; min-height: 60em;">
        <menu-component :links="links" :title="'ADMIN MENU'" :path="'/administrative/'" style="margin: 1em;"/>
        </div>
        
        <!-- Page Column -->
        <div class="column">
        <router-view style="margin : 1em;"> </router-view>
        </div>
      </div>
   
    
    <footer-component />
  </div>
</template>

<script>
import NavigationBar from "../components/NavigationBar";
import Footer from "../components/Footer";
import MenuComponent from "../components/MenuComponent";

export default {
  name: "Administrative",
  components: {
    "navigation-bar": NavigationBar,
    "footer-component": Footer,
    "menu-component": MenuComponent,
  },
  data() {
    return {
      links: [
        {
          title: "Track Issues",
          relative_path: "issue-tracker"
        },
        {
          title: "Register New User",
          relative_path: "register-user"
        },
        {
          title: "Manage Users",
          relative_path: "manage-users"
        },
        {
          title: "Manage Trigger Events",
          relative_path: "manage-triggers"
        },
        {
          title: "Manage Tags",
          relative_path: "add-tags"
        },
        {
          title: "Manage Backups",
          relative_path: "manage-backups"
        },
      ]
    }
  }
};
</script>

<style scoped>
.my-button {
  min-width: 15em;
  text-align: left;
}

.button-box{
    max-width: 15em;
    text-align: left;
    max-height: 5em;
    cursor: pointer;
    border-radius: 15px;

}

.button-box:hover{
    background-color: #F4F6F6;
}
</style>
