<template>

  <div id="eventCard" class="box" style="height:10em; max-width:40em; cursor:pointer; overflow:hidden;"
  @click="getTriggerEvent(triggerEvent.id)">
    
              
    <article class="media">
        <!-- Image of the event -->
      <div class="media-left">
        <figure class="image is-96x96">
          <img v-if="triggerEvent.image_src" :src="triggerEvent.image_src" alt="Image" style="width:96px; height:96px;"/>
          <img v-if="!(triggerEvent.image_src)" src="https://bulma.io/images/placeholders/96x96.png" alt="Image" />
        </figure>
      </div>

      <div class="media-content">
        <div class="content">
          <p class="is-size-6">
            <!-- Name and date of the event -->
            <strong>{{ triggerEvent.event_name }}</strong> &nbsp;
            <small>{{ trigger_date }}</small>
            <!-- Description of the event, can add a couple of tags here -->
            <br />{{ triggerEvent.description }}
          </p>
        </div>
        <!-- Buttons -->
        <nav class="level is-mobile">
          <div class="level-left">
            
          </div>
          <div class="level-right">
            
          </div>
        </nav>
      </div>
    </article>
    
  </div>
  
</template>

<script>
export default {
  name: "EventCard",
  props: [
    'triggerEvent'
  ],
  computed: {
    trigger_date : function() {
      let trigger_timestamp = new Date(this.triggerEvent.occurred_at);
      return trigger_timestamp.toLocaleDateString('en-CA');
    }
  },
  methods: {
    getResponseEventsByTrigger(id){
      let queryObject = {
        triggers: [id],
        tags: null,
        types: null,
        start: null,
        end: null,
      };
      this.$router.push({ name: 'events', params: {queryObject: JSON.stringify(queryObject)}});
      // TODO 
      // get triggerId and request responses from the database
    },
    getTriggerEvent(id){
      this.$router.push({name: 'trigger', params: {triggerId: id}});
    }
  }
};
</script>

<style scoped>
  #eventCard{
    margin: 3%;
  }
  #eventCard:hover{
    background-color: #F4F6F6;
  }
</style>