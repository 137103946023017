<template>
  <div class="comment box">
    <article class="media">
      <!-- Author Profile -->
      <div class="media-left">
        <figure class="image is-64x64">
          <img
            src="https://bulma.io/images/placeholders/128x128.png"
            alt="Image"
          />
        </figure>
      </div>

      <!-- Actual Content -->
      <div class="media-content">
        <div class="content">
          <p>
            <!-- name & date goes here-->
            <strong>{{ comment.username }} </strong> &nbsp; &nbsp;
            <small>{{ posted_at }}</small>
            <br />
            <!-- content goes here-->
            {{ comment.content }}
          </p>
        </div>
      </div>

      <!-- Edit/Delete -->
      <div class="media-left"
      v-if="user_id === this.comment.user_id || user_role ==='admin'">
        <a class="has-text-dark" @click="toggleEditing()">
          <font-awesome-icon :icon="['far', 'edit']" />
        </a>
        <br />
        <a class="" @click="toggleDeleting()"
        style="color:#E74C3C;">
          <font-awesome-icon :icon="['far', 'trash-alt']" />
        </a>
      </div>
    </article>

    <!-- EDIT MODAL -->
    <div class="modal" :class="{ 'is-active': isEditing }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Edit comment</p>
          <button
            class="delete"
            aria-label="close"
            @click="toggleEditing()"
          ></button>
        </header>
        <section class="modal-card-body">
          <!-- Content ... -->
          <div class="field">
            <p class="control">
              <textarea
                class="textarea"
                :placeholder="comment.content"
                v-model="editContent"
                maxlength="500"
                rows="3"
              ></textarea>
            </p>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button v-if="!isEditSuccess && !isEditFailure"
            class="button is-success"
            :class="{ 'is-loading': sendingEdit }"
            @click="editComment()"
          >
            Save changes
          </button>
          <button class="button"
          v-if="!isEditSuccess && !isEditFailure" 
          @click="toggleEditing()">Cancel</button>
          <p v-if="isEditEmpty" class="help is-danger">Enter the comment.</p>
          <div class="notification is-success" style="color: #F4F6F6;
        background-color:#52BE80;" v-if="isEditSuccess">
            The comment has been edited.
          </div>
          <div class="notification is-danger" v-if="isEditFailure"
          style="color: #F4F6F6;
        background-color:#E74C3C;">
            The comment could not be edited. Please try again later.
          </div>
        </footer>
      </div>
    </div>

    <!-- DELETE MODAL -->
    <div class="modal" :class="{ 'is-active': isDeleting }">
      <div class="modal-background"
      @click="toggleDeleting()"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Delete comment</p>
          <button
            class="delete"
            aria-label="close"
            @click="toggleDeleting()"
          ></button>
        </header>
        <section class="modal-card-body">
          <!-- Content ... -->
          <p>Are you sure you want to delete this comment?</p>
        </section>
        <footer class="modal-card-foot">
          <button
            v-if="!isDeleteSuccess && !isDeleteFailure"
            class="button"
            style="color:#E74C3C;"
            :class="{ 'is-loading': sendingDelete }"
            @click="deleteComment()"
          >
            Yes, delete it.
          </button>
          <button 
          v-if="!isDeleteSuccess && !isDeleteFailure"
          class="button" @click="toggleDeleting()">Cancel</button>
          <div class="notification is-success" v-if="isDeleteSuccess"
          style="color: #F4F6F6;
          background-color:#52BE80;">
            The comment has been deleted.
          </div>
          <div class="notification is-danger" v-if="isDeleteFailure"
          style="color: #F4F6F6;
        background-color:#E74C3C;">
            The comment could not be deleted. Please try again later.
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CommentItem",
  props: {
    comment: Object,
    isTriggerComment: Boolean,
  },
  data() {
    return {
      isEditing: false,
      isDeleting: false,
      editContent: this.comment.content,
      isEditEmpty: false,
      sendingEdit: false,
      sendingDelete: false,
      isEditSuccess: false,
      isEditFailure: false,
      isDeleteSuccess: false,
      isDeleteFailure: false,
    };
  },
  methods: {
    /**
     * Posts the edited comment to the database.
     */
    editComment() {
      this.sendingEdit = true;
      this.isEditEmpty = false;
      this.isEditSuccess = false;
      this.isEditFailure = false;
      if (!this.editContent) {
        this.sendingEdit = false;
        this.isEditEmpty = true;
      } else {
        let data = {
          id: this.comment.id,
          content: this.editContent
        };
        let url = "/edit-response-comment";
        if(this.isTriggerComment){
          url = "/edit-trigger-comment";
        }
        axios
          .post(url, data)
          .then(() => {
            this.sendingEdit = false;
            this.isEditSuccess = true;
            setTimeout(() => {
              this.$emit("newComment");
              this.isEditing = false;
              this.isEditSuccess = false;
            }, 1000);
          })
          .catch(() => {
            this.sendingEdit = false;
            this.isEditFailure = true;
          });
      }
    },

    /**
     * Deletes the comment from the database.
     */
    deleteComment() {
      this.sendingDelete = true;
      this.isDeleteSuccess = false;
      this.isDeleteFailure = false;
      let data = {
        id: this.comment.id
      };
      let url = "/delete-response-comment";
        if(this.isTriggerComment){
          url = "/delete-trigger-comment";
        }
      axios
        .post(url, data)
        .then(() => {
          this.sendingDelete = false;
          this.isDeleteSuccess = true;
          setTimeout(() => {
            this.$emit("newComment");
            this.isDeleting = false;
            this.isDeleteSuccess = false;
          }, 1000);
        })
        .catch(() => {
          this.sendingDelete = false;
          this.isDeleteFailure = true;
        });
    },

    toggleEditing() {
      this.isEditing = !this.isEditing;
    },

    toggleDeleting() {
      this.isDeleting = !this.isDeleting;
    }
  },
  computed: {
    posted_at: function() {
      let timestamp = new Date(this.comment.posted_at);
      return timestamp.toLocaleDateString("en-CA");
    },

    user_id: function() {
      return this.$store.getters.getUserId;
    },
    user_role: function(){
      return this.$store.getters.getUserRole;
    }
  }
};
</script>

<style scoped>
.comment {
  margin-bottom: 2px;
  border-bottom: 1px solid gainsboro;
}
</style>
