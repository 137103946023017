<template>
  <div>
    <navigation-bar />
    <div class="section"> 
      <h1 class="is-size-4"> Upload a Trigger Event  >
           </h1>
       <h1 class="is-size-4"> Upload a Trigger Event Without Artifacts
           </h1>
   </div>
   <upload-no-artifact />
   <footer-component />
  </div>
</template>

<script>
import NavigationBar from "../../components/NavigationBar.vue";
import UploadNoArtifact from "../../components/Trigger/UploadNoArtifact";
import Footer from '../../components/Footer';



export default {
  name: "UploadTriggerNoArtifact",
  components: {
    "navigation-bar": NavigationBar,
    "upload-no-artifact": UploadNoArtifact,
    'footer-component': Footer,
  }
};
</script>
