<template>
  <div>
    <navigation-bar />
    <!--Page title-->
    <div class="section title">
      <h1 class="title is-size-4">get youtube comments</h1>
    </div>

    <!--Search section-->
    <div class="section">
      <div class="field">
        <label class="label">enter youtube video id to list top comments</label>
        <div class="control">
          <input class="input" type="text" placeholder="pvU_qqOnlAM" style="width: 50%" />
          <a class="button is-dark" type="submit">search</a>
        </div>
        <p class="has-text-grey">only top comments will be listed.</p>
      </div>
    </div>

    <!-- List comments here. -->
    <youtube-comment-item />
    <footer-component />
  </div>
</template>

<script>
import YoutubeCommentItem from '../components/YoutubeCommentItem';
import NavigationBar from '../components/NavigationBar';
import Footer from "../components/Footer";


export default {
  name: 'GetYoutubeComments',
  components: {
    'youtube-comment-item': YoutubeCommentItem,
    'navigation-bar': NavigationBar,
    'footer-component': Footer,
  }
};
</script>

<style scoped>
   * {
     text-align: left;
   }
</style>
