<template>
  <div>
    <navigation-bar />
    <div class="section"> 
      <h1 class="is-size-4"> Upload a Trigger Event  >
           </h1>
       <h1 class="is-size-4"> Upload a File As a Trigger Event
           </h1>
   </div>
   <upload-trigger-file/>
   <footer-component />
  </div>
</template>

<script>

import NavigationBar from "../../components/NavigationBar.vue";
import UploadTriggerFile from "../../components/Trigger/UploadTriggerFile";
import Footer from '../../components/Footer';


export default {
  name: "UploadTriggerFile",
  components: {
    "navigation-bar": NavigationBar,
    "upload-trigger-file": UploadTriggerFile,
    'footer-component': Footer,
  }
};
</script>
