import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
Vue.use(Vuex);

// state
const state = {
  triggers: [],
  tags: [],
  responses: [],
  currentEvent: {},
  currentSearch: {},
  currentEventTags: [],
  currentTrigger: {},
  triggerTags: [],
  triggerResponses: [],
  eventEditModal: false,
  user: "",
  triggerComments: [],
  responseComments: [],
};

// mutations
const mutations = {
  UPDATE_TRIGGER_EVENTS(state, payload) {
    state.triggers = payload;
  },
  UPDATE_TAGS(state, payload) {
    state.tags = payload;
  },
  UPDATE_RESPONSE_EVENTS(state, payload) {
    state.responses = payload;
  },
  UPDATE_CURRENT_EVENT(state, payload) {
    state.currentEvent = payload;
  },
  UPDATE_CURRENT_SEARCH(state, payload) {
    state.currentSearch = payload;
  },
  UPDATE_CURRENT_TAGS(state, payload) {
    state.currentEventTags = payload;
  },
  UPDATE_CURRENT_TRIGGER(state, payload) {
    state.currentTrigger = payload;
  },
  UPDATE_TRIGGER_TAGS(state, payload) {
    state.triggerTags = payload;
  },
  UPDATE_TRIGGER_RESPONSES(state, payload) {
    state.triggerResponses = payload;
  },
  UPDATE_EVENT_EDIT_MODAL(state, payload) {
    state.eventEditModal = payload;
  },
  UPDATE_USER(state, payload) {
    state.user = payload;
  },
  UPDATE_TRIGGER_COMMENTS(state, payload) {
    state.triggerComments = payload;
  },
  UPDATE_RESPONSE_COMMENTS(state, payload) {
    state.responseComments = payload;
  }
};

// actions, only get commit from the context argument by using {}
const actions = {
  requestTriggerEvents({ commit }) {
    return new Promise((resolve, reject) => {
    axios
      .get("/triggers")
      .then(response => {
        commit("UPDATE_TRIGGER_EVENTS", response.data);
        return resolve();
      })
      .catch(() => {
        commit("UPDATE_TRIGGER_EVENTS", []);
        return resolve();
      });
    });
  },
  requestTags({ commit }) {
    axios
      .get("/tags")
      .then(response => {
        commit("UPDATE_TAGS", response.data);
      })
      .catch(() => {
        commit("UPDATE_TAGS", []);
      });
  },
  requestSearchEvents(context, queryString) {
    axios
      .get("/search?" + queryString)
      .then(response => {
        context.commit("UPDATE_RESPONSE_EVENTS", response.data);
      })
      .catch(() => {
        context.commit("UPDATE_RESPONSE_EVENTS", []);
      });
  },
  requestEventsByTriggers(context, queryObject) {
    axios
      .get("/events-by-triggers/" + queryObject)
      .then(response => {
        context.commit("UPDATE_RESPONSE_EVENTS", response.data);
      })
      .catch(() => {
        context.commit("UPDATE_RESPONSE_EVENTS", []);
      });
  },
  changeCurrentEvent(context, currentEvent) {
    context.commit("UPDATE_CURRENT_EVENT", currentEvent);
  },
  changeCurrentSearch(context, currentSearch) {
    context.commit("UPDATE_CURRENT_SEARCH", currentSearch);
  },
  requestTagsByEvent(context, eventId) {
    axios
      .get("/tags-by-response-id/" + eventId)
      .then(response => {
        context.commit("UPDATE_CURRENT_TAGS", response.data);
      })
      .catch(() => {
        // TODO do something to handle the error.
      });
  },
  requestTriggerInformation(context, triggerId) {
    axios
      .get("/trigger/" + triggerId)
      .then(response => {
        context.commit("UPDATE_CURRENT_TRIGGER", response.data);
      })
      .catch(() => {
        // TODO
      });
  },
  requestTriggerTags(context, triggerId) {
    axios
      .get("/trigger-tags/" + triggerId)
      .then(response => {
        context.commit("UPDATE_TRIGGER_TAGS", response.data);
      })
      .catch(() => {
        // TODO
      });
  },
  requestTriggerResponses(context, triggerId) {
    axios
      .get("/trigger-responses/" + triggerId)
      .then(response => {
        context.commit("UPDATE_TRIGGER_RESPONSES", response.data);
      })
      .catch(() => {
        // TODO
      });
  },
  changeEvenEditModal(context, isActive) {
    context.commit("UPDATE_EVENT_EDIT_MODAL", isActive);
  },
  setUser(context, user) {
    context.commit("UPDATE_USER", user);
  },
  getUser({ commit }) {
    return new Promise((resolve, reject) => {
      let user = store.getters.getUser;

      if (user === "") {
        axios
          .get("/user")
          .then(response => {
            commit("UPDATE_USER", response.data.user);
            return resolve();
          })
          .catch(err => {
            //TODO handle the error here.
            return reject();
          });
      } else {
        return resolve();
      }
    });
  },
  requestTriggerComments(context, triggerId) {
    let url = "/trigger-comments/" + triggerId;
    axios
      .get(url)
      .then(response => {
        context.commit("UPDATE_TRIGGER_COMMENTS", response.data);
      })
      .catch(() => {
        context.commit("UPDATE_TRIGGER_COMMENTS", []);
      });
  },
  resetTriggerComments({ commit }) {
    commit("UPDATE_TRIGGER_COMMENTS", [])
  },
  requestResponseComments(context, responseId) {
    let url = "/response-comments/" + responseId;
    axios
      .get(url)
      .then(response => {
        context.commit("UPDATE_RESPONSE_COMMENTS", response.data);
      })
      .catch(() => {
        context.commit("UPDATE_RESPONSE_COMMENTS", []);
      });
  },
  resetResponseComments({ commit }) {
    commit("UPDATE_RESPONSE_COMMENTS", [])
  },
};

const getters = {
  getTriggerEvents: state => state.triggers,
  getTags: state => state.tags,
  getTagIds: state => state.tags.map(tag => tag.id),
  getResponseEvents: state => state.responses,
  getCurrentEvent: state => state.currentEvent,
  getCurrentSearch: state => state.currentSearch,
  getCurrentTags: state => state.currentEventTags,
  IsCurrentEventEmpty: state => {
    let obj = state.currentEvent;
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  },
  getTriggerNames: state => idArray => {
    let triggers = state.triggers.filter(trigger =>
      idArray.includes(trigger.id)
    );
    return triggers.map(trigger => trigger.event_name);
  },
  getTagsById: state => idArray => {
    let tags = state.tags.filter(tag => idArray.includes(tag.id));
    return tags.map(tag => tag.content);
  },
  getCurrentTrigger: state => state.currentTrigger,
  getTriggerTags: state => state.triggerTags,
  getTriggerResponses: state => state.triggerResponses,
  getEventEditModal: state => state.eventEditModal,
  getOrderedTags: state => {
    return state.tags.sort(function(tagObjectA, tagObjectB) {
      let contentA = tagObjectA.content.toUpperCase();
      let contentB = tagObjectB.content.toUpperCase();

      let comparison = 0;
      if (contentA > contentB) {
        comparison = 1;
      } else if (contentA < contentB) {
        comparison = -1;
      }
      return comparison;
    });
  },
  getUser: state => {
    return state.user;
  },
  getUserRole: state => {
    return state.user.user_role;
  },
  getUsername: state => {
    return state.user.username;
  },
  getUserId: state => {
    return state.user.id;
  },
  getCurrentEventId: state => {
    return state.currentEvent.id;
  },
  getTriggerComments: state => {
    return state.triggerComments;
  },
  getResponseComments: state => {
    return state.responseComments;
  }
};

const store = new Vuex.Store({
  state,
  mutations,
  actions,
  getters
});

export default store;
