<template>
  <div class="">
    <h1 class="is-size-4">Comments ({{ comments.length }}):</h1>
    <!-- comments -->
    <div v-if="comments.length > 0" class="comments-box box">
      <ul v-for="comment in comments" :key="comment.id">
        <li>
          <comment-item
            :comment="comment"
            :isTriggerComment="isTriggerComment"
            v-on:newComment="signalParentForNewComment()"
          />
        </li>
      </ul>
    </div>
    <br />
    <div v-if="!isCommentActive" class="buttons are-medium">
      <div class="button" @click="toggleCommentModule">
        Add comment
      </div>
    </div>

    <!-- Trigger Add Comment Module -->
    <div v-if="isCommentActive" style="margin:0.4em;">
      <h1 class="is-size-5">Post a comment</h1>
      <!-- post a comment -->
      <div class="box">
        <article class="media">
          <div class="media-content">
            <div class="field">
              <p class="control">
                <textarea
                  class="textarea"
                  placeholder="Enter your comment..."
                  v-model="content"
                  maxlength="500"
                  rows="3"
                ></textarea>
              </p>
            </div>
            <nav class="level">
              <div class="level-right">
                <div class="level-item">
                  <a
                    v-if="!isSuccess && !isFailure"
                    class="button is-outlined"
                    @click="postComment()"
                    :class="{ 'is-loading': isPosting }"
                    >Post</a
                  >
                  <p v-if="errors.content" class="help is-danger">
                    {{ errors.content }}
                  </p>
                </div>
              </div>
              <div
                class="notification is-success"
                v-if="isSuccess"
                style="color: #F4F6F6;
        background-color:#52BE80;"
              >
                The comment has been posted successfully.
              </div>
              <div
                class="notification is-danger"
                v-if="isFailure"
                style="color: #F4F6F6;
        background-color:#E74C3C;"
              >
                Something went wrong.
              </div>
            </nav>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
import CommentItem from "./CommentItem";
import axios from "axios";

export default {
  name: "Comments",
  props: {
    comments: Array,
    parent_id: Number,
    isTriggerComment: Boolean,
  },
  components: {
    "comment-item": CommentItem,
  },
  data() {
    return {
      content: "",
      isCommentActive: false,
      errors: {
        content: "",
      },
      isPosting: false,
      isSuccess: false,
      isFailure: false,
    };
  },
  methods: {
    /**
     * Posts a user comment for the event.
     *
     * First checks if the comment content is provided. If
     * it is then it checks the user information and parent
     * event information. According to the parent event information
     * comment is posted under the given response or trigger event.
     *
     */
    postComment() {
      if (!this.checkCommentInput()) {
        return;
      }
      this.isPosting = true;
      this.isSuccess = false;
      this.isFailure = false;

      let id = this.parent_id;
      let user = this.$store.getters.getUser;
      let content = this.content;
      let posted_at = this.getyyyymmdd();

      let commentObject = {
        id: id,
        user_id: user.id,
        content: content,
        posted_at: posted_at,
        username: user.username,
      };

      let url = "";
      if (this.isTriggerComment) {
        url = "/insert-trigger-comment";
      } else {
        url = "/insert-response-comment";
      }

      axios
        .post(url, commentObject)
        .then(() => {
          this.isPosting = false;
          this.isSuccess = true;
          this.signalParentForNewComment();
          setTimeout(() => {
            this.resetComponent();
            this.toggleCommentModule();
          }, 1000);
        })
        .catch(() => {
          this.isPosting = false;
          this.isFailure = true;
        });
    },

    /**
     * Resets the component by deleting the input and removing the notifications.
     */
    resetComponent() {
      this.content = "";
      this.errors.content = "";
      this.isSuccess = false;
      this.isFailure = false;
    },

    /**
     * Checks if the comment input is provided and returns true if it is.
     *
     * If the comment input is not provided then it alerts the
     * user to provide input.
     */
    checkCommentInput() {
      if (this.content) {
        this.errors.content = "";
        return true;
      } else {
        this.errors.content = "Please enter your comment.";
        return false;
      }
    },

    /**
     * Activates/deactivates the post comment module.
     *
     */
    toggleCommentModule() {
      this.isCommentActive = !this.isCommentActive;
    },

    /**
     * Returns the current date in a YYYY-MM-DD format string.
     */
    getyyyymmdd() {
      let date = new Date();
      let year = date.getUTCFullYear();
      let month = date.getUTCMonth() + 1;
      let day = date.getUTCDate();
      let mm = month < 10 ? "0" + month : month;
      let dd = day < 10 ? "0" + day : day;
      return "" + year + "-" + mm + "-" + dd;
    },

    signalParentForNewComment() {
      this.$emit("newComment");
    },
  },
};
</script>

<style scoped>
.comments-box {
  max-height: 20em;
  overflow-y: scroll;
  margin: 0.25em;
}
</style>
