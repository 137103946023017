<template>
  <div class="tag-upload">

    <tag-list />

    <h1 class="is-size-4" style="margin-top: 1em;">Add a New Tag</h1>
    <div class="box">
      <div class="control">
        <input
          class="input"
          type="text"
          v-model="newTag"
          placeholder="new tag"
        />
      </div>
      <div class="control" style="margin-top:1em">
        <button class="button" @click="addTag">Add</button>
      </div>
    </div>

    <div class="section">
      <div v-if="error" class="notification is-danger"
      style="color: #F4F6F6;
        background-color:#E74C3C;">
        {{ error }}
      </div>
      <div v-if="success" class="notification is-success"
      style="color: #F4F6F6;
        background-color:#52BE80;">
        The tag " <strong> {{ newTag }} </strong> " has been added successfully.
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TagList from "./TagList";

export default {
  name: "TagUpload",
  components: {
    'tag-list': TagList,
  },
  data() {
    return {
      newTag: "",
      error: "",
      success: false
    };
  },
  methods: {
    /**
     * Uploads a tag (newTag) if it has not been added before.
     */
    addTag() {
      if (this.tagExists(this.newTag)) {
        this.error = "This tag already exists.";
      } else if (!this.newTag) {
        this.error = "Empty tag cannot be added.";
      } else {
        this.error = "";
        let postUrl = "/upload-tag/" + this.newTag.toLowerCase();
        axios({
          method: "post",
          url: postUrl
        })
          .then(() => {
            this.error = "";
            this.success = true;
            setTimeout(() => {
              this.$router.go();
            }, 1000);
          })
          .catch(() => {
            this.error =
              "Tag cannot be added. Please try again later or report the issue.";
          });
      }
    },

    /**
     * Returns true if the given tag exists
     */
    tagExists(currentTag) {
      let tags = this.$store.getters.getTags;
      if (tags.find(tag => tag.content === currentTag.toLowerCase())) {
        return true;
      }
      return false;
    }
  }
};
</script>

<style scoped>
.tag-upload{
  margin-bottom: 5em;
}
</style>
