<template>
  <div id="fileUploadView">
    <navigation-bar />
    <!--Page title-->
    <div class="section">
      <h1 class="title is-size-4">upload a file</h1>
    </div>
    <file-upload-component />
    <footer-component />
  </div>
</template>

<script>
import NavigationBar from "../components/NavigationBar";
import Footer from "../components/Footer";

export default {
  name: "FileUpload",
  components: {
    "navigation-bar": NavigationBar,
    "footer-component": Footer,
  }
};
</script>

<style scoped>
    #fileUploadView{
        text-align: left;

    }
</style>