<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'app',
  watch: {
    '$route' (to, from) {
      document.title = to.meta.title;
    }
  }
};
</script>

<style>
#app {
  font-family: Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  
  color: #2c3e50;
  min-height: 100vh; /* will cover the 100% of viewport */
  overflow: scroll;
  display: block;
  position: relative;
  
  padding-bottom: 200px; /* height of your footer */
}
html{
  font-size: 50%;
}
</style>
