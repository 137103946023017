<template>
    <div>
    <div class="section">
        <h1 class="is-size-5">
            You are not authorized to see this page. Please contact
            your administrator.
        </h1>
    </div>
    <footer-component />
    </div>
</template>

<script>
import Footer from '../components/Footer';


export default {
    name: 'NotAuthorized',
    components: {
        'footer-component': Footer,
    }
}
</script>