<template>
  <div class="section" style="border:solid; border-width:0.1em ">
    <article class="media">
      <!-- AUTHOR IMAGE CAN GO HERE
      <figure class="media-left">
        <p class="image is-64x64">
          <img src="https://bulma.io/images/placeholders/128x128.png" />
        </p>
      </figure>
      -->
      <div class="media-content">
        <div class="content">
          <p>
            <strong>{{ currentEvent.author }}</strong>
            <br /> {{ currentEvent.content }}
          </p>
        </div>
        <nav class="level is-mobile">
          <div class="level-left">
            
          </div>
        </nav>
      </div>
      <div class="media-right">
        
      </div>
    </article>

  </div>
</template>

<script>

export default {
  name: "YoutubeCommentItem",
  props: [
    'currentEvent'
  ]
};
</script>
