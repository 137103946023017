// include font awesome
import { library } from '@fortawesome/fontawesome-svg-core';
import { faMicroscope, faReply, faRetweet, faHeart, faFilm, faUpload, faAlignJustify, faUserMinus, faDownload } from '@fortawesome/free-solid-svg-icons';
import { faYoutubeSquare, faTwitter, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { faFileAlt, faFilePdf, faImage, faThumbsUp, faEdit, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import Vue from 'vue';
import Vuex from 'vuex';
import App from './App.vue';
import VueCookie from 'vue-cookie';

import Spinner from 'vue-simple-spinner'; // to add spinner component to load pages
Vue.component("vue-simple-spinner", Spinner);

// use bulma framework
import '../public/bulma/css/bulma.css';

// import router and store
import router from './router';
import store from './store';

// use Vuex state management
Vue.use(Vuex);

// use cookie plugin
Vue.use(VueCookie);

// setup font awesome
library.add([faMicroscope, faReply, faRetweet, faHeart, faFilm,
  faUpload, faYoutubeSquare, faTwitter, faFileAlt, faFilePdf,
  faImage, faAlignJustify, faThumbsUp, faYoutube, faEdit, faTrashAlt,
  faUserMinus, faDownload]);
Vue.component('font-awesome-icon', FontAwesomeIcon);


Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app');
