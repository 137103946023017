import { render, staticRenderFns } from "./UploadMain.vue?vue&type=template&id=3fba8363&scoped=true&"
import script from "./UploadMain.vue?vue&type=script&lang=js&"
export * from "./UploadMain.vue?vue&type=script&lang=js&"
import style0 from "./UploadMain.vue?vue&type=style&index=0&id=3fba8363&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fba8363",
  null
  
)

export default component.exports