<template>
    <div style="margin-bottom: 5em;">
        <h1 class="is-size-4" > Manage Trigger Events </h1>
        <h3 class="is-size-6" style="margin-left: 5px; margin-top: 10px" > Current Trigger Events ({{triggers.length}}): </h3>
        <div v-if="!requestingData" class="event-list">
            
            <ul>
                <li v-for="trigger in triggers" :key="trigger.id"> 
                    <event-edit-delete 
                    :trigger_event="trigger" :number_of_responses="getNumberOfResponses(trigger.id)" />
                </li>
                
            </ul>
        </div>
    </div>
</template>

<script>
import EventEditDeleteCard from './EventEditDeleteCard';
import axios from 'axios';

export default {
    name: "ManageTriggerEvents",
    components: {
        'event-edit-delete': EventEditDeleteCard,
    },
    data(){
        return {
            triggers: [],
            responsesArray: [],
            requestingData: false,
            requestError: false,
        }
    },
    computed: {
    },
    created(){
        this.requestingData = true;
        this.requestError = false;
        axios.get('/get-trigger-meta')
        .then((response) => {
            this.triggers = response.data.triggers;
            this.responsesArray = response.data.responses;
            this.requestingData = false;
        })
        .catch(() => {
            this.requestingData = false,
            this.requestError = true;
        });
    },
    methods: {
        getNumberOfResponses(id){
            let trigger = this.responsesArray.find(triggerObj => 
                triggerObj.trigger_id == id);
            if(trigger) return trigger.response_count;
            return 0;
        }
    }
    
}
</script>

<style scoped>
.event-list{
    margin-top:5px;
    margin-left: 5px;
    height: 600px;
    overflow-y: scroll;
    padding: 10px;
    border: solid 1px gainsboro;
    margin-right: 10%;
}


</style>