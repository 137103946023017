<template>
    <div class="box" style="max-height:20em; overflow:scroll;" >
    <ul>
        <li v-for="event in eventList" :key="event.id">
            <event-item :event=event>
            </event-item>
            <br>
        </li>
    </ul>
    </div>
</template>

<script>
import EventListItem from './EventListItem';

export default {
    name: 'EventList',
    props: {
        eventList: Array,
    },
    components: {
        'event-item': EventListItem,
    }
}
</script>

<style scoped>
li {
    margin-top: 1em;
    border-bottom: 0.1em gainsboro solid;
}
</style>