<template>
  <div>
    <navigation-bar />
    <upload-main />
    <footer-component />
  </div>
</template>

<script>
import UploadMain from "../components/UploadMain";
import NavigationBar from "../components/NavigationBar";
import Footer from '../components/Footer';


export default {
  name: "Upload",
  components: {
    "navigation-bar": NavigationBar,
    "upload-main": UploadMain,
    'footer-component': Footer,
  }
};
</script>
