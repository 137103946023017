<template>
  <div>
    <h1 class="is-size-4">Current Tags ({{ orderedTags.length }}):</h1>

    <div class="box" style=" margin-top: 10px;">
      <div class="tag-content">
      <ul>
        <!-- Individual tags -->
        <li v-for="tag in orderedTags" :key="orderedTags.indexOf(tag)">
          <div class="tags has-addons">
            <span class="tag is-size-4">{{ tag.content }}</span>
            <a class="tag is-delete is-size-4" @click="activateDeleteModal(tag)"></a>
          </div>
        </li>
      </ul>
      </div>
    </div>

    <!-- Delete Modal -->
    <div class="modal" :class="{ 'is-active': deleteModal }">
      <div
        class="modal-background"
        style="background-color: rgb(231, 76, 60, 0.8);"
        @click="activateDeleteModal()"
      ></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Delete Tag</p>
          <button
            class="delete"
            aria-label="close"
            @click="activateDeleteModal()"
          ></button>
        </header>
        <section class="modal-card-body">
          <div class="content">
            <p v-if="currentTag">
              Are you sure you want to delete this tag: <strong> {{currentTag.content}} </strong>
            </p>
          </div>
        </section>
        <footer class="modal-card-foot">
          <div v-if="!isDeleteSuccess && !isDeleteFailure">
            <button
              class="button"
              :class="{ 'is-loading': isDeleting }"
              @click="deleteCurrentTag()"
            >
              Yes
            </button>
            <button class="button" @click="activateDeleteModal()">
              No, cancel
            </button>
          </div>
          <div
            v-if="isDeleteSuccess"
            class="notification is-success"
            style="color: #F4F6F6;
        background-color:#52BE80;"
          >
            The tag has been deleted.
          </div>
          <div
            v-if="isDeleteFailure"
            class="notification is-danger"
            style="color: #F4F6F6;
        background-color:#E74C3C;"
          >
            Something went wrong.
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "TagList",
  computed: {
    orderedTags: function() {
      return this.$store.getters.getOrderedTags;
    }
  },
  created() {
    this.$store.dispatch("requestTags");
  },
  data(){
    return {
      currentTag: null,
      deleteModal: false,
      isDeleting: false,
      isDeleteSuccess: false,
      isDeleteFailure: false,
    }
  },
  methods: {
    /**
     * Sends a delete-tag request to the backend with given id.
     */
    deleteCurrentTag(id) {
      this.isDeleting = true;
      this.isDeleteSuccess = false;
      this.isDeleteFailure = false;

      let url = "/delete-tag/" + this.currentTag.id;
      axios.post(url)
      .then(() => {
        this.isDeleting = false;
        this.isDeleteSuccess = true;
        setTimeout(() => {
          this.$router.go();
        }, 1000);
      })
      .catch(() => {
        this.isDeleting = false;
        this.isDeleteFailure = true;
        setTimeout(() => {
          this.$router.go();
        }, 1000);
      })

    },

    /**
     * Activates a confirmation modal for tag deletion.
     *
     */
    activateDeleteModal(tag) {
      if(this.deleteModal){
        this.deleteModal = false;
        this.currentTag = null;
      } else {
        this.isDeleteFailure = false;
        this.isDeleteSuccess = false;
        this.deleteModal = true;
        this.currentTag = tag;
      }
    }
  }
};
</script>

<style scoped>
.tag-content {
  max-height: 50vh;
  overflow-y: scroll;
  margin: 1px;
}

li {
  margin-bottom: 5px;
}
</style>
