<template>
  <div>
    <div class="content">
      <h2>Manage Database</h2>

      <!-- Counts for events and types -->
      <div class="numbers">
        <h3>Numbers</h3>
        <div v-if="metadata" class="columns is-mobile is-multiline">
          <!-- Information Element -->
          <div
            v-for="[key, value] of Object.entries(metadata.numbers.general)"
            :key="key"
            class="column is-one-fifth"
          >
            <div class="box">
              <div class="info">
                <h4>
                  {{ formatLabel(key) }}
                </h4>
                <br />
                {{ value }}
              </div>
            </div>
          </div>

          <!-- numbers for type of events  -->
          <div
            v-for="type of metadata.numbers.typeCounts"
            :key="type.event_type"
            class="column is-one-fifth"
          >
            <div class="box">
              <div class="info">
                <h4>
                  {{ formatLabel(type.event_type) }}
                </h4>
                <br />
                {{ type.count }}
              </div>
            </div>
          </div>
        </div>
        <!-- Couldn't load metadata -->
        <div v-else-if="metadataError" class="notification is-danger">
          Cannot load the backups info at the moment.
        </div>
        <div v-else class="loading">
          <vue-simple-spinner line-fg-color="#363636"></vue-simple-spinner>
        </div>
      </div>

      <!-- Storage size information -->
      <div class="storage-info">
        <h3>Storage</h3>
        <div v-if="metadata" class="columns is-mobile is-multiline">
          <!-- Information Element -->
          <div
            v-for="[key, value] of Object.entries(metadata.storage)"
            :key="key"
            class="column is-one-fifth"
          >
            <div class="box info">
              <h4>
                {{ formatLabel(key) }}
              </h4>
              <br />
              {{ value }}
            </div>
          </div>
        </div>
        <!-- Couldn't load metadata -->
        <div v-else-if="metadataError" class="notification is-danger">
          Cannot load the backups info at the moment.
        </div>
        <div v-else class="loading">
          <vue-simple-spinner line-fg-color="#363636"></vue-simple-spinner>
        </div>
      </div>

      <!-- Separate Numbers from the backup information -->
      <hr />

      <div class="backups">
        <h3>Backups</h3>

        <!-- Backups Table -->
        <table v-if="backups != null && backups.length > 0" class="table">
          <!-- Table Header -->
          <thead>
            <tr>
              <th><abbr title="Backup Id">Id</abbr></th>
              <th><abbr title="By User">By</abbr></th>
              <th>Date</th>
              <th><abbr title="Number Of Trigger Events">Trgs</abbr></th>
              <th><abbr title="Number Of Response Events">Rspns</abbr></th>
              <th><abbr title="Number Of Users">Usrs</abbr></th>
              <th><abbr title="Total Backup Size">Total</abbr></th>
              <th><abbr title="Total Size of Images in Backup">Img</abbr></th>
              <th><abbr title="Total Size of Videos in Backup">Vid</abbr></th>
              <th><abbr title="Total Size of Pdfs in Backup">Pdf</abbr></th>
              <th>Delete</th>
              <th>Download</th>
            </tr>
          </thead>

          <!-- Table Body -->
          <tbody>
            <!-- Table Row -->

            <tr v-for="backup in backups" :key="backup.id">
              <td>
                <!-- id -->
                {{ backup.id }}
              </td>
              <td>
                <!-- username -->
                {{ backup.username }}
              </td>
              <td>
                <!-- date -->
                {{ getDateString(backup.backup_date) }}
              </td>
              <td>
                <!-- number of triggers  -->
                {{ backup.total_triggers }}
              </td>
              <td>
                <!-- number of response events  -->
                {{ backup.total_responses }}
              </td>
              <td>
                <!-- number of users  -->
                {{ backup.total_users }}
              </td>
              <td>
                <!-- total size  -->
                {{ backup.backup_size }}
              </td>
              <td>
                <!-- image size  -->
                {{ backup.image_size }}
              </td>
              <td>
                <!-- video size  -->
                {{ backup.video_size }}
              </td>
              <td>
                <!-- pdf size  -->
                {{ backup.pdf_size }}
              </td>
              <td>
                <font-awesome-icon
                  @click="toggleDeleteBackup(backup)"
                  :icon="['far', 'trash-alt']"
                  class="icon-button"
                />
              </td>

              <td>
                <font-awesome-icon
                  @click="downloadBackup(backup.id)"
                  :icon="['fas', 'download']"
                  class="icon-button"
                />
              </td>
            </tr>
          </tbody>
        </table>

        <!-- Cannot load backups data -->
        <div v-else-if="noBackups" class="notification">
          There are no backups.
        </div>
        <div v-else-if="backupsError" class="notification is-danger">
          Cannot load the backups info at the moment.
        </div>
        <div v-else class="loading">
          <vue-simple-spinner line-fg-color="#363636"></vue-simple-spinner>
        </div>
        <div v-if="backupDownloadError" class="notification is-danger">
          Cannot download backup now.
        </div>
      </div>

      <div class="backup">
        <h3>Database Backup</h3>
        <div class="notification">
          Before backing up the database, be sure to check following: <br />
          There is enough storage available for a full backup. <br />
          There is no backup for the same day.
        </div>

        <button
          class="button is-dark"
          :class="{ 'is-loading': isBackingup }"
          @click="backupDatabase()"
        >
          Backup Database
        </button>

        <div v-if="backupProgressBar" class="backup-progress">
          <progress class="progress" :value="backupProgress" max="100">
          </progress>
        </div>

        <div
          v-if="backupError"
          class="notification is-danger backup-notification"
          style="color: #F4F6F6;
        background-color:#E74C3C;"
        >
          Something went wrong. Try again later or report an issue.
        </div>

        <div
          v-if="backupSuccess"
          class="notification is-success backup-notification"
          style="color: #F4F6F6;
        background-color:#52BE80;"
        >
          The backup has been added successfully.
        </div>
      </div>

      <!-- Delete database modal -->
      <div class="modal" :class="{ 'is-active': isDeleteModalActive }">
        <div
          class="modal-background"
          style="background-color: rgb(231, 76, 60, 0.8);"
          @click="toggleDeleteBackup(null)"
        ></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <h3 class="modal-card-title">Delete Backup</h3>
            <button
              class="delete"
              aria-label="close"
              @click="toggleDeleteBackup(null)"
            ></button>
          </header>
          <section class="modal-card-body">
            <div>
              <h4>
                Are you sure you want to delete this backup?
              </h4>
            </div>
            <br />

            <div>
              <table v-if="selectedBackup" class="table">
                <!-- Table Header -->
                <thead>
                  <tr>
                    <th><abbr title="Backup Id">Id</abbr></th>
                    <th><abbr title="By User">By</abbr></th>
                    <th>Date</th>
                    <th><abbr title="Number Of Trigger Events">Trgs</abbr></th>
                    <th>
                      <abbr title="Number Of Response Events">Rspns</abbr>
                    </th>
                    <th><abbr title="Number Of Users">Usrs</abbr></th>
                    <th><abbr title="Total Backup Size">Total</abbr></th>
                    <th>
                      <abbr title="Total Size of Images in Backup">Img</abbr>
                    </th>
                    <th>
                      <abbr title="Total Size of Videos in Backup">Vid</abbr>
                    </th>
                    <th>
                      <abbr title="Total Size of Pdfs in Backup">Pdf</abbr>
                    </th>
                  </tr>
                </thead>

                <!-- Table Body -->
                <tbody>
                  <!-- Table Row -->

                  <tr>
                    <td>
                      <!-- id -->
                      {{ selectedBackup.id }}
                    </td>
                    <td>
                      <!-- username -->
                      {{ selectedBackup.username }}
                    </td>
                    <td>
                      <!-- date -->
                      {{ getDateString(selectedBackup.backup_date) }}
                    </td>
                    <td>
                      <!-- number of triggers  -->
                      {{ selectedBackup.total_triggers }}
                    </td>
                    <td>
                      <!-- number of response events  -->
                      {{ selectedBackup.total_responses }}
                    </td>
                    <td>
                      <!-- number of users  -->
                      {{ selectedBackup.total_users }}
                    </td>
                    <td>
                      <!-- total size  -->
                      {{ selectedBackup.backup_size }}
                    </td>
                    <td>
                      <!-- image size  -->
                      {{ selectedBackup.image_size }}
                    </td>
                    <td>
                      <!-- video size  -->
                      {{ selectedBackup.video_size }}
                    </td>
                    <td>
                      <!-- pdf size  -->
                      {{ selectedBackup.pdf_size }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <footer class="modal-card-foot">
            <div v-if="!isDeleteSuccess && !isDeleteFailure">
              <button
                class="button"
                :class="{ 'is-loading': isDeleting }"
                @click="deleteBackup(selectedBackup.id)"
              >
                Yes
              </button>
              <button class="button" @click="toggleDeleteBackup(null)">
                No, cancel
              </button>
            </div>
            <div
              v-if="isDeleteSuccess"
              class="notification is-success"
              style="color: #F4F6F6;
        background-color:#52BE80;"
            >
              The backup has been deleted.
            </div>
            <div
              v-if="isDeleteFailure"
              class="notification is-danger"
              style="color: #F4F6F6;
        background-color:#E74C3C;"
            >
              Something went wrong. Try again later or report an issue.
            </div>
          </footer>
        </div>
      </div>
    </div>


    <!-- Modal to disable functionality when downloading a backup -->
    <div v-if="isDownloadingBackup"  class="download">
      <div class="modal is-active">
        <div class="modal-background"></div>
        <div class="modal-content">
          
          <vue-simple-spinner
            size="huge"
            line-fg-color="#363636"
          ></vue-simple-spinner>
          <br/>
          <p> This may take a couple of minutes. <br/> Please wait. </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "ManageBackups",
  data() {
    return {
      metadata: null,
      backups: null,
      metadataError: false,
      backupsError: false,
      isDeleteModalActive: false,
      isDeleteSuccess: false,
      isDeleteFailure: false,
      isDeleting: false,
      selectedBackup: null,
      noBackups: false,
      isBackingup: false,
      backupError: false,
      backupSuccess: false,
      backupProgress: 0,
      backupProgressBar: false,
      isDownloadingBackup: false,
      backupDownloadError: false,
    };
  },
  created() {
    this.getMetadata();
    this.getBackups();
  },
  methods: {
    // gets the database metadata from backend
    getMetadata() {
      this.metadataError = false;
      axios
        .get("/database-metadata")
        .then((response) => {
          this.metadata = response.data;
        })
        .catch(() => {
          this.metadataError = true;
        });
    },

    // gets backup data from backend
    getBackups() {
      this.backupsError = false;
      this.noBackups = false;
      axios
        .get("/backups")
        .then((response) => {
          this.backups = response.data;
          if (this.backups != null && this.backups.length == 0)
            this.noBackups = true;
        })
        .catch(() => {
          this.backupsError = true;
        });
    },

    // backups the database
    backupDatabase() {
      // reset ui
      this.backupProgress = 0;
      this.backupProgressBar = true;
      this.backupError = false;
      this.backupSuccess = false;
      this.isBackingup = true;

      this.handleBackupProgress();

      let url = "/add-backup";
      axios
        .post(url)
        .then(() => {
          this.backupProgress = 100;
          this.backupSuccess = true;
          this.isBackingup = false;
          setTimeout(() => {
            this.refreshBackups();
            this.backupProgressBar = false;
          }, 2000);
        })
        .catch(() => {
          this.isBackingup = false;
          this.backupError = true;
          this.backupProgressBar = false;
        });
    },

    // deletes the backup given id and updates the ui
    deleteBackup(id) {
      // todo
      this.isDeleting = true;
      let url = "/delete-backup/" + id;
      axios
        .post(url)
        .then(() => {
          this.isDeleting = false;
          this.isDeleteSuccess = true;

          // refresh the delete modal and backups info after 3 seconds
          setTimeout(() => {
            this.refreshBackups();
            this.refreshDeleteModal();
          }, 3000);
        })
        .catch(() => {
          this.isDeleting = false;
          this.isDeleteFailure = true;
          this.refreshBackups();
        });
    },

    // downloads the backup as a zip file
    downloadBackup(id) {
      this.isDownloadingBackup = true;
      let url = "/download-backup/" + id;
      axios({
        url: url,
        method: "GET",
        responseType: "arraybuffer",
      })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/zip" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = "EmodisseyBackup.zip";
          link.click();
          this.isDownloadingBackup = false;
          this.backupDownloadError = false;
        })
        .catch((err) => {
          this.backupDownloadError = true;
          this.isDownloadingBackup = false;
        });
    },

    // toggles the delete backup confirmation modal
    toggleDeleteBackup(backup) {
      this.selectedBackup = backup;
      this.isDeleteModalActive = !this.isDeleteModalActive;
    },

    // returns the local date string given a date string
    getDateString(date) {
      let dateString = new Date(date);
      return dateString.toLocaleString("en-CA");
    },

    // refreshes backups info
    refreshBackups() {
      this.noBackups = false;
      this.selectedBackup = null;
      this.backups = null;
      this.getBackups();
    },

    // divides the given string by the "_" character and makes
    // the first character uppercase
    formatLabel(string) {
      string = string.split("_").join(" ");
      return string[0].toUpperCase() + string.slice(1);
    },

    // refreshes the fields of the delete modal
    refreshDeleteModal() {
      this.isDeleteModalActive = false;
      this.isDeleteSuccess = false;
      this.isDeleteFailure = false;
    },

    // fills the ui progress bar during backup
    async handleBackupProgress() {
      this.backupProgress++;
      // until you get result from the backend, increase the status bar
      if (
        this.backupProgress < 90 &&
        !this.backupSuccess &&
        !this.backupError
      ) {
        setTimeout(() => {
          this.handleBackupProgress();
        }, 50);
      }
    },
  },
};
</script>

<style scoped>
.content {
  margin-top: 2em;
  margin-left: 2em;
  margin-right: 2em;
}

h2 {
  margin-bottom: 3em;
}

h3 {
  margin-bottom: 1.5em;
}

.numbers {
  margin-bottom: 3em;
}

.storage-info {
  margin-bottom: 3em;
}

.backups {
  margin-bottom: 3em;
}

.info {
  text-align: center;
}

.box {
  min-height: 10em;
}

.backup-notification {
  margin-top: 1em;
}

.backup-progress {
  margin-top: 1em;
}

.icon-button:hover {
  cursor: pointer;
}

.box:hover {
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.11),
    0 0 0 1px rgba(10, 10, 10, 0.11);
}

.download {
  font-size: 2em;
  color: whitesmoke;
  text-align: center;
}
</style>
