<template>
  <div>
    {{ /* Navbar */ }}
    <nav
      class="navbar is-size-4"
      role="navigation"
      aria-label="main navigation"
      style="border-bottom: solid 1px black;"
    >
      <div class="navbar-brand">
        <a class="navbar-item is-size-4" id="title" href="/">
          Emodissey
        </a>
        <a
          role="button"
          class="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarItems"
          onclick="document.querySelector('#navbarItems').classList.toggle('is-active')"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="navbarItems" class="navbar-menu">
        <div class="navbar-end">
          <div class="navbar-item"></div>
        </div>
      </div>
    </nav>
    <div class="section">
      <h1 class="is-size-5">Please login to proceed.</h1>
    </div>
    <div class="section">
    <login-component />
    </div>
    <footer-component />
  </div>
</template>

<script>
import Login from "../components/Login.vue";
import Footer from "../components/Footer.vue";

export default {
  name: "LoginView",
  components: {
    "login-component": Login,
    "footer-component": Footer,
  }
};
</script>

<style scoped>
 #title:hover{
     color:gold;
 }
</style>
