<template>
  <div class="upload-response">
    <div class="upload-fields">
      <!--File upload-->
      <div class=" file-upload field">
        <label class="label is-size-5">Choose a file</label>
        <div class="file has-name is-boxed">
          <label class="file-label">
            <input
              class="file-input"
              id="file"
              ref="file"
              type="file"
              @change="handleFile"
              style="width: 10em;"
            />
            <span class="file-cta">
              <span class="file-icon">
                <font-awesome-icon :icon="['fas', 'upload']" />
              </span>
              <span class="file-label"></span>
            </span>
            <span class="file-name">{{ fileName }}</span>
          </label>
        </div>
        <!-- no file warning -->
        <p v-if="errorObject.file" class="help is-danger">
          {{ errorObject.file }}
        </p>
        <!-- file type warning -->
        <p v-if="errorObject.fileType" class="help is-danger">
          {{ errorObject.fileType }}
        </p>
      </div>

      <!--File metadata-->

      <br />
      <!-- Name -->
      <div class="field" style="width: 60%;">
        <label class="label is-size-5">Event name</label>
        <input
          class="input"
          id="event_name"
          type="text"
          v-model="event_name"
          placeholder="name the event"
          maxlength="500"
        />
        <!-- no name warning -->
        <p v-if="errorObject.name" class="help is-danger">
          {{ errorObject.name }}
        </p>
      </div>
      <br />

      <!-- Description -->
      <div class="field" style="width: 60%;">
        <label class="label is-size-5">Event description</label>
        <div class="box">
          <textarea
            class="textarea"
            placeholder="describe the event"
            v-model="eventDescription"
            maxlength="1000"
          ></textarea>
        </div>
        <!-- no description warning -->
        <p v-if="errorObject.description" class="help is-danger">
          {{ errorObject.description }}
        </p>
      </div>
      <br />

      <!-- Trigger event -->
      <div class="field">
        <label class="label is-size-5">Select a trigger event</label>
        <div class="control">
          <div class="select is-medium" style="width: 60%;">
            <select v-model="selectedTrigger" style="width: 100%;">
              <option
                v-for="trigger in triggerEvents"
                :key="trigger.id"
                :id="trigger.id"
                :value="trigger.id"
              >
                {{ trigger.event_name }}
              </option>
            </select>
          </div>
        </div>
        <!-- no trigger warning -->
        <p v-if="errorObject.trigger" class="help is-danger">
          {{ errorObject.trigger }}
        </p>
      </div>
      <br />

      <!-- Tags Input -->
      <div class="field is-size-5">
        <label class="label is-size-5">Select tags</label>
        <tags-input
          :tags="tags"
          @tags-changed="updateSelectedTagIds"
          style="width: 150%;"
        />
        <!-- no tags warning -->
        <p v-if="errorObject.tags" class="help is-danger">
          {{ errorObject.tags }}
        </p>
      </div>

      <br />

      <!-- Date Input -->
      <div class="field">
        <label class="label is-size-5">Enter date</label>
        <functional-calendar class="date-picker" 
          :is-date-picker="true"
          :change-month-function="true"
          :change-year-function="true"
          :dateFormat="'yyyy/mm/dd'"
          :is-modal="true"
          v-on:dayClicked="changeDate"> 
        </functional-calendar>
        <!-- no date warning -->
        <p v-if="errorObject.eventDate" class="help is-danger">
          {{ errorObject.eventDate }}
        </p>
      </div>
      <br />

      <div
        v-if="success"
        class="notification is-success"
        style="color: #F4F6F6;
        background-color:#52BE80;"
      >
        <button class="delete" @click="success = false"></button>
        The response event <b> {{ event_name }} </b> has been uploaded
        successfully.
      </div>
      <div
        v-if="failure"
        class="notification is-danger"
        style="color: #F4F6F6;
        background-color:#E74C3C;"
      >
        <button class="delete" @click="failure = false"></button>
        The response event <b> {{ event_name }} </b> could not be uploaded.
        Please try again later or report an issue.
      </div>

      <div class="field control">
        <a
          class="button"
          :class="{ 'is-loading': sending }"
          type="button"
          @click="upload"
          >Upload</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
axios.defaults.maxContentLength = Infinity;
import TagsInput from "../TagsInput";
import { FunctionalCalendar } from 'vue-functional-calendar';

export default {
  name: "UploadResponseFile",
  components: {
    "tags-input": TagsInput,
    "functional-calendar": FunctionalCalendar,
  },
  data() {
    return {
      file: "",
      fileName: "",
      selectedTrigger: "",
      selectedTriggerName: "",
      checkedTagIds: [],
      eventDate: "",
      eventDescription: "",
      event_name: "",
      selectedType: "",
      sending: false,
      fileType: "",
      errorObject: {
        file: "",
        trigger: "",
        tags: "",
        eventDate: "",
        description: "",
        name: "",
        type: "",
        fileType: "",
      },
      success: false,
      failure: false,
      selected: "",
    };
  },
  created() {
    this.$store.dispatch("requestTriggerEvents");
    this.$store.dispatch("requestTags");
  },
  computed: {
    triggerEvents: function() {
      return this.$store.getters.getTriggerEvents;
    },
    tags: function() {
      return this.$store.getters.getTags;
    },
    tagIds: function(){
      return this.$store.getters.getTagIds;
    }
  },
  methods: {
    // populates file data and handles file  name display when user select a file
    handleFile() {
      this.file = this.$refs.file.files[0];
      this.fileName = this.file.name;
    },
    /* Checks if all the required fields are completed for file upload
      fields:
      file, selectedTrigger, checkedTags, eventDate, eventDescription, event_name, selectedType
    */
    checkErrors() {
      // file
      if (this.fileName.length < 1) {
        this.errorObject.file = "Please select a file to upload.";
      } else {
        if (this.getAndCheckFileType()) {
          this.errorObject.fileType = "";
        } else {
          this.errorObject.fileType =
            "Supported file types are: " +
            "bmp, gif, ico, jpg, jpeg, png, svg, pdf, and mp4.";
        }
        this.errorObject.file = "";
      }
      // selectedTrigger
      if (this.selectedTrigger.length < 1) {
        this.errorObject.trigger = "Please select a trigger event.";
      } else {
        this.errorObject.trigger = "";
      }
      // checkedTags
      if (this.checkedTagIds.length < 1) {
        this.errorObject.tags = "Please select tag(s).";
      } else {
        let tags = this.checkedTagIds.filter(tagId => this.tagIds.includes(tagId));
        if(tags.length < 1) this.errorObject.tags = "Please select tag(s) again.";
        else {
          this.errorObject.tags = "";
          this.checkedTagIds = tags;
        }
      }

      // eventDate
      if (this.eventDate.length < 1){
        this.errorObject.eventDate = "Please pick a date."; 
      } else {
        this.errorObject.eventDate = "";
      }

      // eventDescription
      if (this.eventDescription.length < 1) {
        this.errorObject.description =
          "Please provide a description for the event.";
      } else {
        this.errorObject.description = "";
      }
      // event_name
      if (this.event_name.length < 1) {
        this.errorObject.name = "Please give it a name.";
      } else {
        this.errorObject.name = "";
      }
    },

    /** Checks if there is any error with form info by checking the error object.
     *If no errors returns true; false otherwise.
     */
    requiredFileInfoGiven() {
      return (
        this.errorObject.name.length === 0 &&
        this.errorObject.file.length === 0 &&
        this.errorObject.fileType.length === 0 &&
        this.errorObject.description.length === 0 &&
        this.errorObject.tags.length === 0 &&
        this.errorObject.trigger.length === 0 &&
        this.errorObject.eventDate.length === 0
      );
    },

    /** Builds and returns a form data by appending file
     * and file metadata to a FormData object.
     */
    getFormData() {
      let formData = new FormData();
      let type = this.getCorrectDatabaseType();
      let triggerName = this.getTriggerName(this.selectedTrigger);
      formData.append("file", this.$refs.file.files[0]);
      formData.append("type", type);
      formData.append("name", this.event_name);
      formData.append("description", this.eventDescription);
      formData.append("trigger", this.selectedTrigger);
      formData.append("trigger_name", triggerName);
      formData.append("eventDate", this.eventDate);
      formData.append("tags", this.checkedTagIds);
      return formData;
    },

    // sends the file and file information to the backend
    upload() {
      this.checkErrors();
      if (this.requiredFileInfoGiven()) {
        let formData = this.getFormData();
        this.sending = true;
        this.success = false;
        this.failure = false;

        // send file and file metadata
        axios({
          method: "post",
          url: "/upload-response-file",
          data: formData,
          headers: {
            "content-type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        })
          .then(() => {
            this.sending = false;
            this.success = true;
            setTimeout(() => {
              this.$router.go();
            }, 1000);
          })
          .catch(() => {
            this.sending = false;
            this.failure = true;
          });
      }
    },

    // returns the name of the selected trigger event given its id
    getTriggerName(id) {
      let triggerName = this.triggerEvents.find((trigger) => trigger.id === id);
      return triggerName.event_name;
    },

    // returns true if the file has the a file type as one of the following:
    // "bmp, gif, ico, jpg, jpeg, png, svg, pdf, mp4"
    getAndCheckFileType() {
      let fileTypes = [
        "bmp",
        "gif",
        "ico",
        "jpg",
        "jpeg",
        "png",
        "svg",
        "pdf",
        "mp4",
      ];
      let nameSplitArray = this.fileName.split(".");
      let type = nameSplitArray.pop();
      this.fileType = type;
      return fileTypes.includes(type);
    },

    // returns the correct database type "image", "pdf", "video"
    getCorrectDatabaseType() {
      let imageTypes = ["bmp", "gif", "ico", "jpg", "jpeg", "png", "svg"];
      let pdf = "pdf";
      let video = "mp4";
      let type = this.fileType;

      if (imageTypes.includes(type)) {
        return "image";
      } else if (type === pdf) {
        return "pdf";
      } else if (type === video) {
        return "video";
      } else {
        return "other"; // it is not supposed to come here.
      }
    },

    updateSelectedTagIds(tagIds) {
      this.checkedTagIds = tagIds;
    },

    changeDate(newDate){
      this.eventDate = newDate.date.split("/").join("-");
      console.log(this.eventDate);
    }
  },
};
</script>

<style scoped>
.upload-response {
  margin-bottom: 5em;
}

.upload-fields {
  width: 80%;
  margin-left: 5%;
  margin-right: 5%;
  padding: 5%;
  border: solid gainsboro 1px;
}

.date-box {
  width: 50px;
}

</style>
