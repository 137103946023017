<template>
  <div>

      <!-- User card -->
    <div
      class="user-card"
      style="margin-bottom:10px; border: solid 1px gainsboro; padding: 20px;"
    >
      <article class="media">
        <div class="media-left">
          {{ index }}
        </div>
        <div class="media-content">
          <div class="content">
            <p>
              <strong>{{ user.username }}</strong> &nbsp; &nbsp;
              <b v-if="user.user_role === 'admin'" style="color: red">
                admin
              </b>
              <small v-else>{{ user.user_role }}</small>
              <br />
              {{ user.email }}
            </p>
          </div>
        </div>
        <div class="media-right is-size-4" style="margin-right: 50px;;">
          <a class="has-text-dark" @click="toggleDeleteModal">
            <font-awesome-icon :icon="['fas', 'user-minus']" />
          </a>
        </div>
      </article>
    </div>

    <!-- Delete Modal -->
    <div class="modal" :class="{'is-active': isDeleteModalActive}">
        <div class="modal-background" style="background-color: rgb(231, 76, 60, 0.8);"
        @click="toggleDeleteModal"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title" >Delete User</p>
            <button class="delete" aria-label="close"
            @click="toggleDeleteModal"></button>
          </header>
          <section class="modal-card-body">
            <div class="content">
                <p>
                    Are you sure you want to delete user <em>{{user.username}}</em> ?
                </p>
            </div>

          </section>
          <footer class="modal-card-foot">
            <div v-if="!isDeleteSuccess && !isDeleteFailure">
            <button class="button" :class="{'is-loading': isDeleting}"
            @click="deleteUser">Yes</button>
            <button class="button" @click="toggleDeleteModal">No, cancel</button>
            </div>
            <div v-if="isDeleteSuccess" class="notification is-success"
              style="color: #F4F6F6;
        background-color:#52BE80;">
                The user {{user.username}} has been deleted.
              </div>
              <div v-if="isDeleteFailure" class="notification is-danger"
              style="color: #F4F6F6;
        background-color:#E74C3C;">
                Something went wrong.
              </div>
          </footer>
        </div>
      </div>

  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "UserCard",
  props: {
    user: Object,
    index: Number
  },
  data(){
      return {
         isDeleteModalActive: false,
         isDeleteSuccess: false,
         isDeleteFailure: false,
         isDeleting: false,
      }
  },
  methods: {
      toggleDeleteModal(){
          this.isDeleteModalActive = !this.isDeleteModalActive;
      },
      deleteUser(){
          this.isDeleting = true;
          this.isDeleteSuccess = false;
          this.isDeleteFailure =false;
          let url = "/delete-user/" + this.user.id;
          axios.post(url)
          .then(() => {
              this.isDeleting = false;
              this.isDeleteSuccess = true;
              setTimeout(() => {
                  this.$router.go();
              }, 1000);
          })
          .catch(() => {
              this.isDeleteFailure = true;
              this.isDeleting = false;
              setTimeout(() => {
                  this.$router.go();
              }, 1000);
          });
      }
  }
};
</script>

<style scoped></style>
