import { render, staticRenderFns } from "./YoutubeCommentItem.vue?vue&type=template&id=2d5fb89a&"
import script from "./YoutubeCommentItem.vue?vue&type=script&lang=js&"
export * from "./YoutubeCommentItem.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports