import Vue from 'vue';
import VueRouter from 'vue-router';
import Welcome from '../views/Welcome.vue';
import Browse from '../views/Browse.vue';
import Search from '../views/Search.vue';
import UploadResponse from '../views/UploadResponse.vue';
import FileUpload from '../views/FileUpload.vue';
import GetYoutubeComments from '../views/GetYoutubeComments.vue';
import NotFound from '../views/NotFound.vue';
import Events from '../views/Events.vue';
import Trigger from '../views/Trigger.vue';
import UploadTrigger from '../views/UploadTrigger.vue';
import Upload from '../views/Upload';
import UploadResponseFile from '../views/upload-response/UploadResponseFile';
import UploadResponseTweets from '../views/upload-response/UploadTweets';
import UploadResponseTweetLink from '../views/upload-response/UploadTweetLink';
import UploadResponseYoutube from '../views/upload-response/UploadYoutube';
import UploadResponseYoutubeComment from '../views/upload-response/UploadYoutubeComment';
import UploadResponseYoutubeCommentManual from '../views/upload-response/UploadYoutubeCommentManual';
import UploadTriggerFile from '../views/upload-trigger/UploadTriggerFile';
import UploadTriggerNoArtifact from '../views/upload-trigger/UploadTriggerNoArtifact';
import Home from '../views/Home';
import store from '../store';
import NotAuthorized from '../views/NotAuthorized';
import Login from '../views/Login';
import Administrative from '../views/Administrative';
import TagUploadComponent from "../components/TagUpload";
import RegisterUserComponent from "../components/RegisterUser";
import IssueTracker from "../components/IssueTracker";
import ManageTriggerEvents from "../components/ManageTriggerEvents";
import ManageUsers from "../components/ManageUsers";
import ManageBackups from "../components/ManageBackups";


// Use VueRouter
Vue.use(VueRouter);

// create the routes
const routes = [
  {
    path: '/',
    component: Welcome,
    meta: {
      title: 'Emodissey'
    },
    beforeEnter: (to, from, next) => {
      let user = store.getters.getUser;
      if(!user) next();
      else next('/home');
    }
  },
  {
    path: '/events/:queryString',
    name: 'events',
    component: Events,
    meta: {
      title: 'Emodissey - Search Results'
    },
    beforeEnter: (to, from, next) => {
      let user = store.getters.getUser;
      if(!user) next('/login');
      else next();
    }
  },
  {
    path: '/browse',
    component: Browse,
    meta: {
      title: 'Emodissey - Browse'
    },
    beforeEnter:(to, from, next) => {
      let user = store.getters.getUser;
      if(!user) next('/login');
      else next();
    }
  },
  {
    path: '/home',
    component: Home,
    meta: {
      title: 'Emodissey - Welcome'
    },
    beforeEnter:(to, from, next) => {
      let user = store.getters.getUser;
      if(!user) next('/login');
      else next();
    }
  },
  {
    path: '/trigger/:triggerId',
    name: 'trigger',
    component: Trigger,
    meta: {
      title: 'Emodissey - Trigger Event'
    },
    beforeEnter:(to, from, next) => {
      let user = store.getters.getUser;
      if(!user) next('/login');
      else next();
    }
  },
  {
    path: '/search',
    component: Search,
    meta: {
      title: 'Emodissey - Search'
    },
    beforeEnter:(to, from, next) => {
      let user = store.getters.getUser;
      if(!user) next('/login');
      else next();
    }
  },
  {
    path: '/upload',
    component: Upload,
    meta: {
      title: 'Emodissey - Upload'
    },
    beforeEnter:(to, from, next) => {
      let user = store.getters.getUser;
      if(!user) next('/login');
      else if (user.user_role === 'watcher') next('/not-authorized');
      else next();
    }
  },
  {
    path: '/upload-file',
    component: FileUpload,
    meta: {
      title: 'Emodissey - Upload File'
    },
    beforeEnter:(to, from, next) => {
      let user = store.getters.getUser;
      if(!user) next('/login');
      else if (user.user_role === 'watcher') next('/not-authorized');
      else next();
    }
  },
  {
    path: '/upload-youtube',
    component: GetYoutubeComments,
    meta: {
      title: 'Emodissey - Youtube Comments' 
    },
    beforeEnter:(to, from, next) => {
      let user = store.getters.getUser;
      if(!user) next('/login');
      else if (user.user_role === 'watcher') next('/not-authorized');
      else next();
    }
  },
  {
    path: '/upload/trigger',
    component: UploadTrigger,
    meta: {
      title: 'Emodissey - Upload Trigger Event'
    },
    beforeEnter:(to, from, next) => {
      let user = store.getters.getUser;
      if(!user) next('/login');
      else if (user.user_role === 'watcher') next('/not-authorized');
      else next();
    }
  },
  {
    path: '/upload/response',
    component: UploadResponse,
    meta: {
      title: 'Emodissey - Upload Response Event'
    },
    beforeEnter:(to, from, next) => {
      let user = store.getters.getUser;
      if(!user) next('/login');
      else if (user.user_role === 'watcher') next('/not-authorized');
      else next();
    }
  },
  {
    path: '/upload/response/file',
    component: UploadResponseFile,
    meta: {
      title: 'Emodissey - Upload Response File'
    },
    beforeEnter:(to, from, next) => {
      let user = store.getters.getUser;
      if(!user) next('/login');
      else if (user.user_role === 'watcher') next('/not-authorized');
      else next();
    }
  },
  {
    path: '/upload/response/tweet',
    component: UploadResponseTweets,
    meta: {
      title: 'Emodissey - Upload Tweet'
    },
    beforeEnter:(to, from, next) => {
      let user = store.getters.getUser;
      if(!user) next('/login');
      else if (user.user_role === 'watcher') next('/not-authorized');
      else next();
    }
  },
  {
    path: '/upload/response/tweet-link',
    component: UploadResponseTweetLink,
    meta: {
      title: 'Emodissey - Upload Tweet via Link'
    },
    beforeEnter:(to, from, next) => {
      let user = store.getters.getUser;
      if(!user) next('/login');
      else if (user.user_role === 'watcher') next('/not-authorized');
      else next();
    }
  },
  {
    path: '/upload/response/youtube',
    component: UploadResponseYoutube,
    meta: {
      title: 'Emodissey - Upload Video via Link'
    },
    beforeEnter:(to, from, next) => {
      let user = store.getters.getUser;
      if(!user) next('/login');
      else if (user.user_role === 'watcher') next('/not-authorized');
      else next();
    }
  },
  {
    path: '/upload/response/youtube-comments',
    component: UploadResponseYoutubeComment,
    meta: {
      title: 'Emodissey - Upload Youtube Comments'
    },
    beforeEnter:(to, from, next) => {
      let user = store.getters.getUser;
      if(!user) next('/login');
      else if (user.user_role === 'watcher') next('/not-authorized');
      else next();
    }
  },
  {
    path: '/upload/response/youtube-comment-manual',
    component: UploadResponseYoutubeCommentManual,
    meta: {
      title: 'Emodissey - Upload Youtube Comment via Link'
    },
    beforeEnter:(to, from, next) => {
      let user = store.getters.getUser;
      if(!user) next('/login');
      else if (user.user_role === 'watcher') next('/not-authorized');
      else next();
    }
  },
  {
    path: '/upload/trigger/file',
    component: UploadTriggerFile,
    meta: {
      title: 'Emodissey - Upload Trigger File'
    },
    beforeEnter:(to, from, next) => {
      let user = store.getters.getUser;
      if(!user) next('/login');
      else if (user.user_role === 'watcher') next('/not-authorized');
      else next();
    }
  },
  {
    path: '/upload/trigger/without-artifact',
    component: UploadTriggerNoArtifact,
    meta: {
      title: 'Emodissey - Upload Trigger Without Artifact'
    },
    beforeEnter:(to, from, next) => {
      let user = store.getters.getUser;
      if(!user) next('/login');
      else if (user.user_role === 'watcher') next('/not-authorized');
      else next();
    }
  },
  {
    path: '/administrative',
    component: Administrative,
    meta: {
      title: 'Emodissey - Admin'
    },
    beforeEnter:(to, from, next) => {
      let user = store.getters.getUser;
      if(!user) next('/login');
      else if (user.user_role !== 'admin') next('/not-authorized');
      else next();
    },
    children: [
      {
        path: 'add-tags',
        component: TagUploadComponent,
        meta: {
          title: 'Emodissey - Add Tags'
        },
      },
      {
        path: 'register-user',
        component: RegisterUserComponent,
        meta: {
          title: 'Emodissey - Register User'
        },
      },
      {
        path: 'issue-tracker',
        component: IssueTracker,
        meta: {
          title: 'Emodissey - Track Issues'
        },
      },
      {
        path: 'manage-triggers',
        component: ManageTriggerEvents,
        meta: {
          title: 'Emodissey - Manage Triggers'
        },
      },
      {
        path: 'manage-users',
        component: ManageUsers,
        meta: {
          title: 'Emodissey - Manage Users'
        },
      },
      {
        path: 'manage-backups',
        component: ManageBackups,
        meta: {
          title: 'Emodissey - Manage Backups'
        },
      }

    ]
  },
  {
    path: '/not-authorized',
    component: NotAuthorized,
    meta: {
      title: 'Emodissey - Not Authorized'
    }
  },
  {
    path: '/login',
    component: Login,
    meta: {
      title: 'Emodissey - Login'
    }
  },
  {
    path: '*',
    component: NotFound,
    meta: {
      title: 'Emodissey - Not Found'
    }
  }
];

// Create the router
const router = new VueRouter({
  mode: 'history', // to disable # routing
  routes,
});

router.beforeEach((to, from, next) => {
  store.dispatch('getUser')
  .then(() => {
    next();
  })
  .catch(() => {
    next();
  })
})

export default router;
