<template>
  <div class="upload-trigger upload-fields">
    <div class="section is-size-6">
      <!--File upload-->
      <div class=" file-upload">
        <label class="label is-size-4">File</label>
        <div class="file has-name is-boxed">
          <label class="file-label">
            <input
              class="file-input"
              id="file"
              ref="file"
              type="file"
              name="file"
              @change="handleFile"
            />
            <span class="file-cta">
              <span class="file-icon">
                <font-awesome-icon :icon="['fas', 'upload']" />
              </span>
              <span class="file-label"></span>
            </span>
            <span class="file-name">{{ fileName }}</span>
          </label>
        </div>
        <!-- no file warning -->
        <p v-if="errorObject.file" class="help is-danger">
          {{ errorObject.file }}
        </p>
        <!-- file type warning -->
        <p v-if="errorObject.fileType" class="help is-danger">
          {{ errorObject.fileType }}
        </p>
      </div>
      <br />

      <!--image upload-->
      <div class="file-upload">
        <label class="label is-size-4">Image for the trigger event</label>
        <div class="file has-name is-boxed">
          <label class="file-label">
            <input
              class="file-input"
              id="imageFile"
              ref="image"
              type="file"
              name="imageFile"
              @change="handleImage"
            />
            <span class="file-cta">
              <span class="file-icon">
                <font-awesome-icon :icon="['fas', 'upload']" />
              </span>
              <span class="file-label"></span>
            </span>
            <span class="file-name">{{ imageName }}</span>
          </label>
        </div>
        <!-- no file warning -->
        <p v-if="errorObject.image_file" class="help is-danger">
          {{ errorObject.image_file }}
        </p>
      </div>

      <br />

      <!-- Name -->
      <div class="field">
        <label class="label is-size-4">Event name</label>
        <input
          class="input"
          id="event_name"
          type="text"
          v-model="event_name"
          placeholder="name the event"
          style="width: 60%"
          maxlength="500"
        />
        <!-- no name warning -->
        <p v-if="errorObject.name" class="help is-danger">
          {{ errorObject.name }}
        </p>
      </div>

      <br />

      <!-- Description -->
      <div class="field">
        <label class="label is-size-4">Event description</label>
        <div class="box" style="width: 60%">
          <textarea
            class="textarea"
            placeholder="describe the event"
            v-model="eventDescription"
            maxlength="1000"
          ></textarea>
        </div>
        <!-- no description warning -->
        <p v-if="errorObject.description" class="help is-danger">
          {{ errorObject.description }}
        </p>
      </div>
      <br />

      <!-- Date Input -->
      <div class="field">
        <label class="label is-size-5">Enter date</label>
        <functional-calendar
          class="date-picker"
          :is-date-picker="true"
          :change-month-function="true"
          :change-year-function="true"
          :dateFormat="'yyyy/mm/dd'"
          :is-modal="true"
          v-on:dayClicked="changeDate"
        >
        </functional-calendar>
        <!-- no date warning -->
        <p v-if="errorObject.eventDate" class="help is-danger">
          {{ errorObject.eventDate }}
        </p>
      </div>

      <br />

      <div
        v-if="success"
        class="notification is-success"
        style="color: #F4F6F6;
        background-color:#52BE80;"
      >
        <button class="delete" @click="success = false"></button>
        The trigger event <b> {{ event_name }} </b> has been uploaded
        successfully.
      </div>
      <div
        v-if="failure"
        class="notification is-danger"
        style="color: #F4F6F6;
        background-color:#E74C3C;"
      >
        <button class="delete" @click="failure = false"></button>
        The trigger event <b> {{ event_name }} </b> could not be uploaded.
        Please try again later or <a href="#"> report an issue </a>.
      </div>

      <div class="field control">
        <a
          v-if="!success && !failure"
          class="button"
          :class="{ 'is-loading': sending }"
          type="button"
          @click="upload"
          >Upload</a
        >
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
axios.defaults.maxContentLength = Infinity;
import { FunctionalCalendar } from 'vue-functional-calendar';

export default {
  name: "UploadTriggerFile",
  components:{
    "functional-calendar": FunctionalCalendar,
  },
  data() {
    return {
      file: "",
      fileName: "",
      eventDate: "",
      eventDescription: "",
      event_name: "",
      fileType: "",
      sending: false,
      imageFile: "",
      imageName: "",
      errorObject: {
        file: "",
        eventDate: "",
        description: "",
        name: "",
        type: "",
        imageFile: "",
        fileType: "",
      },
      success: false,
      failure: false,
    };
  },
  methods: {
    // populates file data and handles file  name display when user select a file
    handleFile() {
      this.file = this.$refs.file.files[0];
      this.fileName = this.file.name;
      let type = this.fileName.split(".").pop();
      this.fileType = type;
    },

    handleImage() {
      this.imageFile = this.$refs.image.files[0];
      this.imageName = this.imageFile.name;
    },

    /* Checks if all the required fields are completed for file upload
      fields:
      file, selectedTrigger, checkedTags, eventDate, eventDescription, event_name, selectedType
    */
    checkErrors() {
      // file
      if (this.fileName.length < 1) {
        this.errorObject.file = "Please select a file to upload.";
      } else {
        if (this.getAndCheckFileType()) {
          this.errorObject.fileType = "";
        } else {
          this.errorObject.fileType =
            "Supported file types are: " +
            "bmp, gif, ico, jpg, jpeg, png, svg, pdf, mp4";
        }
        this.errorObject.file = "";
      }
     
      // eventDate
      if (this.eventDate.length < 1){
        this.errorObject.eventDate = "Please pick a date."; 
      } else {
        this.errorObject.eventDate = "";
      }

      // eventDescription
      if (this.eventDescription.length < 1) {
        this.errorObject.description =
          "Please provide a description for the event.";
      } else {
        this.errorObject.description = "";
      }
      // event_name
      if (this.event_name.length < 1) {
        this.errorObject.name = "Please give it a name.";
      } else {
        this.errorObject.name = "";
      }

      // image
      if (this.imageFile.length < 1) {
        this.errorObject.imageFile = "Please select an image.";
      } else {
        let type = this.imageFile.name.split(".").pop();
        let imageTypes = ["bmp", "gif", "ico", "jpg", "jpeg", "png", "svg"];
        if (imageTypes.includes(type)) {
          this.errorObject.imageFile = "";
        } else {
          this.errorObject.imageFile =
            "Incorrect image type. Provide an image with one of the following types: " +
            "bmp, gif, ico, jpg, jpeg, png, or svg";
        }
      }
    },

    /** Checks if there is any error with form info by checking the error object.
     *If no errors returns true; false otherwise.
     */
    requiredFileInfoGiven() {
      return (
        this.errorObject.name.length === 0 &&
        this.errorObject.file.length === 0 &&
        this.errorObject.fileType.length === 0 &&
        this.errorObject.description.length === 0 &&
        this.errorObject.eventDate.length === 0 &&
        this.errorObject.imageFile.length === 0
      );
    },

    /** Builds and returns a form data by appending file
     * and file metadata to a FormData object.
     */
    getFormData() {
      let formData = new FormData();
      let type = this.getCorrectDatabaseType();
      formData.append("file", this.$refs.file.files[0]);
      formData.append("image", this.$refs.image.files[0]);
      formData.append("type", type);
      formData.append("name", this.event_name);
      formData.append("description", this.eventDescription);
      formData.append("eventDate", this.eventDate);
      return formData;
    },

    // sends the file and file information to the backend
    upload() {
      this.checkErrors();
      if (this.requiredFileInfoGiven()) {
        let formData = this.getFormData();
        this.sending = true;
        this.success = false;
        this.failure = false;

        // send file and file metadata
        axios({
          method: "post",
          url: "/upload-trigger-file",
          data: formData,
          headers: {
            "content-type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        })
          .then((response) => {
            this.sending = false;
            this.failure = false;
            this.success = true;
            // refresh the page in 3 seconds
            setTimeout(() => {
              this.$router.go();
            }, 1000);
          })
          .catch((err) => {
            this.sending = false;
            this.success = false;
            this.failure = true;
            // TODO handle upload failure
            setTimeout(() => {
              this.$router.go();
            }, 1000);
          });
      }
    },

    // returns true if the file has the a file type as one of the following:
    // "bmp, gif, ico, jpg, jpeg, png, svg, pdf, mp4"
    getAndCheckFileType() {
      let fileTypes = [
        "bmp",
        "gif",
        "ico",
        "jpg",
        "jpeg",
        "png",
        "svg",
        "pdf",
        "mp4",
      ];
      return fileTypes.includes(this.fileType);
    },

    // returns the correct database type "image", "pdf", "video"
    getCorrectDatabaseType() {
      let imageTypes = ["bmp", "gif", "ico", "jpg", "jpeg", "png", "svg"];
      let pdf = "pdf";
      let video = "mp4";
      let type = this.fileType;

      if (imageTypes.includes(type)) {
        return "image";
      } else if (type === pdf) {
        return "pdf";
      } else if (type === video) {
        return "video";
      } else {
        return "other"; // it is not supposed to come here.
      }
    },

    // updates the selected event date based on date-picker event
    changeDate(newDate){
      this.eventDate = newDate.date.split("/").join("-");
      console.log(this.eventDate);
    }
  },
};
</script>

<style scoped>
.upload-trigger {
  margin-bottom: 5em;
}

.upload-fields {
  width: 80%;
  margin-left: 5%;
  margin-right: 5%;
  padding: 5%;
  border: solid gainsboro 1px;
}

.date-box {
  width: 50px;
}
</style>
