<template>
    <div>
        <h2 class="is-size-4"> Manage Users </h2>
        <h3 class="is-size-6" style="margin-top: 10px;"> Current users ({{users.length}}): </h3>
        <div class="box manage-users">
            <div class="user-list">
                <ul>
                    <li v-for="user in users" :key="user.id">
                        <user-card :user="user" :index="users.indexOf(user) + 1" />
                    </li>
                </ul>
            </div>
        </div>
        <div v-if="loadError" class="margin: 10px;">
            User data cannot be loaded at the moment. Please try again later.
        </div>
    </div>
</template>

<script>
import UserCard from "./UserCard";
import axios from 'axios';

export default {
    name: "ManageUsers",
    components: {
        'user-card': UserCard,
    },
    data() {
        return {
            users: [],
            loadError: false,
        }
    },
    created(){
        this.loadError = false;
        axios.get("/get-users")
        .then((response) => {
            this.users = response.data;
        })
        .catch((err) => {
            this.loadError = true;
        })
    },
    
}
</script>


<style scoped>
.manage-users{
   margin-top: 20px;
   height: 50vh;
   overflow-y: scroll;
   padding: 10px;
}
</style>