<template>
  <div class="register-user">
    
      <h1 class="is-size-4">Register User</h1>
    
    <div class=" register">
      <div class="box" style="max-width:40em; margin-top: 1em;">
        <div class="field">
          <label class="label is-size-5">username</label>
          <div class="control">
            <input
              class="input is-size-6"
              type="text"
              placeholder="user"
              v-model="username"
            />
          </div>
          <p v-if="errors.username" class="help is-danger">
            Enter username
          </p>
        </div>

        <div class="field">
          <label class="label is-size-5">role</label>
          <div class="control" >
            <label class="radio is-size-6" v-for="role in roles" :key="roles.indexOf(role)">
              <input type="radio" v-model="selectedRole" :name="role" :value="role"/>
              {{ role }}
            </label>
          </div>
          <p v-if="errors.role" class="help is-danger">
            Select role
          </p>
        </div>

        <div class="field">
          <label class="label is-size-5">email</label>
          <div class="control">
            <input
              class="input is-size-6"
              type="email"
              placeholder="example@example.com"
              v-model="email"
            />
          </div>
          <p v-if="errors.email" class="help is-danger">
            Enter email
          </p>
        </div>

        <div class="field">
          <label class="label is-size-5">password</label>
          <div class="control">
            <input
              class="input is-size-6"
              type="password"
              placeholder="******"
              v-model="password"
            />
          </div>
          <p v-if="errors.password" class="help is-danger">
            Enter password
          </p>
        </div>

        <div class="field">
          <div class="control">
            <div class="buttons is-right">
              <div class="button is-size-6" @click="register()" :class="{ 'is-loading': sending }">
                Register
              </div>
            </div>
          </div>
        </div>

        <div v-if="registerSuccess" class="notification is-success"
        style="color: #F4F6F6;
        background-color:#52BE80;">
          User has been registered successfully.
        </div>
        <div v-if="errors.register" class="notification is-danger"
        style="color: #F4F6F6;
        background-color:#E74C3C;">
          {{ errors.register }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "RegisterUser",
  data() {
    return {
      username: "",
      email: "",
      password: "",
      selectedRole: "",
      roles: ["admin", "collector", "watcher"],
      errors: {
        username: "",
        email: "",
        password: "",
        register: "",
        role: "",
      },
      sending: false,
      registerSuccess: false,
    };
  },
  methods: {


    checkFields(){
      let result = true;
      if(!this.username) {
        result = false;
        this.errors.username = "Enter username";
      } else {
        this.errors.username = "";
      }
      if(!this.email) {
        result = false;
        this.errors.email = "Enter email";
      } else {
        this.errors.email = "";
      }
      if(!this.password) {
        result = false;
        this.errors.password = "Enter password";
      } else {
        this.errors.password = "";
      }
      if(!this.selectedRole) {
        result = false;
        this.errors.role = "Select a role";
      } else {
        this.errors.role = "";
      }
      return result;
    },

    register(){
      if(!this.checkFields()) return;
      this.sending = true;
      this.errors.register = "";
      this.registerSuccess = false;
      let user = {
        username: this.username,
        role: this.selectedRole,
        email: this.email,
        password: this.password
      }

      
      axios({
          method: 'post',
          url: '/register',
          data: user,
      })
      .then(() => {
        this.sending = false;
        this.registerSuccess = true;
        setTimeout(() => {
          this.$router.go()
        }, 1000);
      })
      .catch((error) => {
        this.sending = false;
        if(error.response.status === 409){
          this.errors.register = "Username or email already exists."
        } else {
          this.errors.register = "Something went wrong. Try again later.";
        }
      })

    }
  }
};
</script>

<style scoped>
.register-user{
  margin-bottom: 5em;
}
</style>>
