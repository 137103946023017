<template>
    <div>
     <div class="issue-component"> 
         <h1 class="is-size-4"> Submitted Issues: </h1>
     </div>
     <div class="issue-list">
         <template v-if="loadingIssues">
             <!-- TODO --- create a spinner component... -->
             
         </template>
          <template v-else-if="loadingError">
              <div class="notification is-danger"
             style="color: #F4F6F6; background-color:#E74C3C;">
            Something went wrong. Please try again later.
          </div>
          </template>
         <template v-else>
             <ul>
                 <li v-for="issue in issues" :key="issue.id"> 
                     <issue-item :issue="issue" />
                 </li>
             </ul>
         </template>
     </div>
    </div>
</template>

<script>
import IssueItem from "./IssueItem";
import axios from "axios";


export default {
    name: "IssueTracker",
    components: {
        'issue-item': IssueItem,
    },
    data(){
        return {
            issues: [],
            loadingIssues: false,
            loadingError: false,
        }
    },
    methods: {
        getIssues(){
            this.loadingIssues = true;
            axios.get("/get-issues")
            .then((response) => {
                this.issues = response.data;
                this.loadingIssues = false;
            })
            .catch(() => {
                this.loadingError = true;
                this.loadingIssues = false;
            });
        },
    },
    created(){
        this.getIssues();
    }
}
</script>