<template>
<div style="overflow-y: scroll">
    <navigation-bar />
    <div class="columns">
        
        <div class="column is-half" style="border-right: 1px solid black">
            <search-result />
        </div>

        <div class="column is-half">
            <event-info v-if="isEventClicked" />
        </div>
    </div>
    <footer-component />
</div>
    
</template>

<script>
import SearchResult from '../components/SearchResult';
import EventInfo from '../components/EventInfo';
import NavigationBar from '../components/NavigationBar';
import Footer from "../components/Footer";

export default {
    name: 'Events',
    computed: {
        isEventClicked: function() {
            return !(this.$store.getters.IsCurrentEventEmpty);
        }
    },
    components: {
        'search-result': SearchResult,
        'event-info': EventInfo,
        'navigation-bar': NavigationBar,
        'footer-component': Footer,
    }
}
</script>