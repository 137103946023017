<template>
  <div class="upload-response-youtube">
    <!-- Search Field -->
    <div class="section">
      <h1 class="is-size-5">Link for the youtube video</h1>
      <div class="field" style="margin-right:10em; min-width:15em;">
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="https://youtu.be/WWS747M7UnQ"
            v-model="youtubeUrl"
            style=" border-color:#FF0000"
            @keydown.enter="getVideo()"
          />
        </div>
        <div class="control">
          <div class="buttons is-right">
            <button
              class="button"
              v-bind:class="{ 'is-loading': isGettingVideo }"
              @click="getVideo()"
              style=" border-color:#FF0000; margin-top:1em;"
            >
              Get Video
            </button>
          </div>
        </div>
        <div class="control">
          <p v-if="noInput" class="help is-danger">
            Please provide something to search.
          </p>
          <p v-if="noValidUrl" class="help is-danger">
            Please provide a valid youtube video url.
          </p>
          <p v-if="noVideoInfo" class="help is-danger">
            {{ noVideoInfo }}
          </p>
        </div>
      </div>
    </div>

    <!-- Video and the Upload Fields -->
    <div class="section" v-if="video">
      <div class="columns">
        <div class="column is-half">
          <div class="section">
            <h1 class="is-size-5">Video:</h1>
            <br />

            <div class="iframe-container">
              <iframe
                :src="'https://www.youtube.com/embed/' + video.id"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              >
              </iframe>
            </div>
          </div>
        </div>
        <div class="column is-half">
          <div class="section">
            <h1 class="is-size-5">Upload this video:</h1>
            <br />
            <!-- Upload Info Fields -->
            <div class="box">
              <!-- Form -->
              <div class="field">
                <label class="label is-medium">Description</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="description"
                    placeholder="Enter a description for comment(s)"
                    maxlength="1000"
                  />
                </div>
                <!-- no description warning -->
                <p v-if="errorObject.description" class="help is-danger">
                  {{ errorObject.description }}
                </p>
              </div>

              <!-- Trigger Input -->
              <div class="field">
                <label class="label is-medium">Select a trigger event</label>
                <div class="control">
                  <div class="select is-medium">
                    <select v-model="selectedTrigger">
                      <option
                        v-for="trigger in triggerEvents"
                        :key="trigger.id"
                        :id="trigger.id"
                        :value="trigger.id"
                      >
                        {{ trigger.event_name }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- no trigger warning -->
                <p v-if="errorObject.trigger" class="help is-danger">
                  {{ errorObject.trigger }}
                </p>
              </div>

              <!-- Tags Input -->
              <div class="field" style="margin-bottom: 5em;">
                <label class="label is-medium">Select tags</label>
                <tags-input :tags="tags" @tags-changed="updateSelectedTagIds" />
                <!-- no tags warning -->
                <p v-if="errorObject.tags" class="help is-danger">
                  {{ errorObject.tags }}
                </p>
              </div>
              <div class="control">
                <div class="buttons is-right">
                  <button
                    class="button"
                    v-bind:class="{ 'is-loading': isUploading }"
                    @click="uploadVideo()"
                    style=" border-color:#FF0000; margin-top:1em;"
                  >
                    Upload
                  </button>
                </div>
              </div>
              <div class="control">
                <p v-if="postReqError" class="help is-danger"
                style="color: #F4F6F6;
        background-color:#E74C3C;">
                  Cannot be uploaded at the moment. Please try again later or
                  report it as an issue.
                </p>
                <div v-if="uploadSuccess" class="notification is-success"
                style="color: #F4F6F6;
        background-color:#52BE80;">
                  Youtube video has been uploaded successfully.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TagsInput from "../TagsInput";

export default {
  name: "UploadYoutube",
  components: {
    "tags-input": TagsInput
  },
  data() {
    return {
      noInput: "",
      noValidUrl: "",
      isGettingVideo: false,
      youtubeUrl: "",
      description: "",
      selectedTrigger: "",
      selectedTagIds: [],
      postReqError: false,
      isUploading: false,
      video: "",
      noVideoInfo: "",
      uploadSuccess: false,
      errorObject: {
        tags: "",
        trigger: "",
        description: ""
      }
    };
  },
  created() {
    this.$store.dispatch("requestTriggerEvents");
    this.$store.dispatch("requestTags");
  },
  computed: {
    triggerEvents: function() {
      return this.$store.getters.getTriggerEvents;
    },
    tags: function() {
      return this.$store.getters.getTags;
    }
  },
  methods: {
    getVideo() {
      if (!this.checkSearchInput()) return false;
      let videoId = this.getVideoId();
      let url = "/youtube-info/" + videoId;
      this.isGettingVideo = true;
      this.noVideoInfo = "";
      axios
        .get(url)
        .then(response => {
          this.isGettingVideo = false;
          this.video = response.data;
        })
        .catch(() => {
          this.isGettingVideo = false;
          this.video = "";
          this.noVideoInfo = "Cannot get information on this video.";
        });
    },
    uploadVideo() {
      if (!this.checkUploadFields()) {
        return;
      }
      let formObject = this.getFormObject();
      this.isUploading = true;
      axios({
        method: "post",
        url: "/upload-response",
        data: formObject
      })
        .then(() => {
          this.isUploading = false;
          this.postReqError = false;
          this.uploadSuccess = true;
          setTimeout(() => {
            this.$router.go();
          }, 1000);
        })
        .catch(() => {
          this.isUploading = false;
          this.uploadSuccess = false;
          this.postReqError = true;
        });
    },
    checkSearchInput() {
      this.noInput = false;
      this.noValidUrl = false;
      if (!this.youtubeUrl) {
        this.noInput = true;
        return false;
      }
      if (!this.checkValidUrl()) {
        this.noValidUrl = true;
        return false;
      }
      return true;
    },

    // checks if given url is a valid youtube url
    checkValidUrl() {
      let regex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
      let match = this.youtubeUrl.match(regex);
      return match !== null;
    },
    // gets the video id from the given url
    getVideoId() {
      let regex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
      let videoId = this.youtubeUrl.match(regex);
      return videoId[1];
    },

    // checks if all the upload fields are given
    checkUploadFields() {
      let result = true;
      // description
      if (this.description.length < 1) {
        this.errorObject.description = "Please provide a description.";
        result = false;
      } else {
        this.errorObject.description = "";
      }
      // trigger
      if (this.selectedTrigger.length < 1) {
        this.errorObject.trigger = "Please select a trigger event.";
        result = false;
      } else {
        this.errorObject.trigger = "";
      }

      return result;
    },

    // creates formatted string to represent the date
    createDateString(dateString) {
      const date = new Date(dateString);
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getUTCFullYear();
      let formattedString = year + "-" + month + "-" + day;
      return formattedString;
    },
    // returns the name of the selected trigger event given its id
    getTriggerName(id) {
      let triggerName = this.triggerEvents.find(trigger => trigger.id === id);
      return triggerName.event_name;
    },

    getFormObject() {
      let formData = new FormData();
      let trigger_name = this.getTriggerName(this.selectedTrigger);
      let events = [];
      let event = {
        event_name: this.video.title.substring(0, 240),
        description: this.description,
        event_type: "youtube",
        src: this.video.id,
        content: "NULL",
        occurred_at: this.createDateString(this.video.published_at),
        author: this.video.author,
        trigger_id: this.selectedTrigger,
        trigger_name: trigger_name,
        external_id: this.video.id
      };
      events.push(event);

      formData.append("events", JSON.stringify(events));
      formData.append("tags", JSON.stringify(this.selectedTagIds));
      return formData;
    },

    updateSelectedTagIds(idArray) {
      this.selectedTagIds = idArray;
    }

    // TODO request video info!!!
  }
};
</script>

<style scoped>
.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  margin-right: 5em;
  height: 2em;
}

.iframe-container iframe {
  border: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

/* 4x3 Aspect Ratio */
.iframe-container-4x3 {
  padding-top: 75%;
}

.upload-response-youtube {
  margin-bottom: 5em;
}
</style>
