<template>
  <div class="upload-response-youtube-comment">
    <div class="columns">
      <div class="column is-half">
        <!-- Search Field -->
        <div class="section">
          <h1 class="is-size-5">Link for the youtube video</h1>
          <div class="field" style="margin-right:10em; min-width:15em;">
            <div class="control">
              <input
                class="input"
                type="text"
                placeholder="paste youtube video url such as: https://youtu.be/WWS747M7UnQ"
                v-model="youtubeUrl"
                style=" border-color:#FF0000"
                @keydown.enter="requestComments()"
              />
              <p class="info">
              This will get the most relevant 30 comments for the video.
              </p>
            </div>
            <div class="control">
              <div class="buttons is-right">
                <button
                  class="button"
                  v-bind:class="{ 'is-loading': isActive }"
                  @click="requestComments"
                  style=" border-color:#FF0000"
                >
                  Search
                </button>
              </div>
            </div>
            <div class="control">
              <p v-if="noUrl" class="help is-danger">
                Please provide something to search.
              </p>
            </div>
          </div>
        </div>
      </div>

      <!-- the video column -->
      <div v-if="videoId" class="column is-half">
        <h1 class="is-size-5">Video:</h1>
        <div class="iframe-container">
          <iframe
            :src="'https://www.youtube.com/embed/' + this.videoId"
            frameborder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          >
          </iframe>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-two-thirds">
        <!-- Result comments cards column -->
        <!-- Comment Cards -->
        <div class="section">
          <div
            class="box"
            style="margin-right: auto; max-width:50em;"
            v-for="comment in comments"
            :key="comment.id"
          >
            <article class="media">
              <figure class="media-left">
                <p class="image is-64x64">
                  <img :src="comment.author_image" />
                </p>
              </figure>

              <div class="media-content">
                <div class="content">
                  <p>
                    <strong>{{ comment.author }}</strong>
                    <br />
                    {{ comment.content }}
                  </p>
                  <font-awesome-icon :icon="['far', 'thumbs-up']" />
                  {{ comment.likes }}
                </div>
                <nav class="level is-mobile">
                  <div class="level-left"></div>
                </nav>
              </div>

              <div class="media-right">
                <div class="field">
                  <div class="control">
                    <label class="checkbox">
                      <input
                        type="checkbox"
                        v-model="selectedComments"
                        :value="comment"
                      />
                      Select
                    </label>
                  </div>
                </div>

                <div class="field">
                  <div class="control">
                    <button
                      class="button"
                      @click="selectAndUpload(comment)"
                      style=" border-color:#FF0000"
                    >
                      Upload this comment
                    </button>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>

      <div class="column">
        <!-- Selected comments view column. -->
        <br />
        <h1 class="is-size-5" v-if="selectedComments.length > 0">
          Selected comments
        </h1>
        <br />
        <div
          class="box"
          style="max-height:20em;  margin-right:5em; overflow: scroll;"
          v-if="selectedComments.length > 0"
        >
          Selected Comments: {{ selectedComments.length }}

          <div class="control">
            <div class="buttons is-right">
              <button class="button" @click="clearSelected">Clear all</button>
              <button
                class="button"
                @click="isUploadModalActive = true"
                style=" border-color:#FF0000"
              >
                Upload all
              </button>
            </div>
          </div>

          <br />
          <ul>
            <li v-for="comment in selectedComments" :key="comment.id">
              <p>
                <strong> {{ comment.author }} </strong>
              </p>
              <p>{{ comment.content }}</p>
              <br />
            </li>
          </ul>

          <div class="control">
            <div class="buttons is-right">
              <button class="button" @click="clearSelected">Clear all</button>
              <button
                class="button"
                @click="isUploadModalActive = true"
                style=" border-color:#FF0000"
              >
                Upload all
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Upload Comments Modal -->
      <div class="modal is-active" v-if="isUploadModalActive">
        <div
          class="modal-background"
          style="background-color: aliceblue; opacity: 0.7;"
        ></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Upload Comments</p>
            <button
              class="delete"
              aria-label="close"
              @click="closeUploadModal"
            ></button>
          </header>
          <section class="modal-card-body">
            Number of comments selected: {{ selectedComments.length }}

            <!-- Form -->
            <div class="field">
              <label class="label is-medium">Description</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model="description"
                  placeholder="Enter a description for comment(s)"
                  maxlength="1000"
                />
              </div>
              <!-- no description warning -->
              <p v-if="errorObject.description" class="help is-danger">
                {{ errorObject.description }}
              </p>
            </div>

            <!-- Trigger Input -->
            <div class="field">
              <label class="label is-medium">Select a trigger event</label>
              <div class="control">
                <div class="select is-medium">
                  <select v-model="selectedTrigger">
                    <option
                      v-for="trigger in triggerEvents"
                      :key="trigger.id"
                      :id="trigger.id"
                      :value="trigger.id"
                    >
                      {{ trigger.event_name }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- no trigger warning -->
              <p v-if="errorObject.trigger" class="help is-danger">
                {{ errorObject.trigger }}
              </p>
            </div>

            <!-- Tags Input -->
            <div class="field " style="margin-bottom: 5em;">
              <label class="label is-medium" >Select tags</label>
              <tags-input :tags="tags" @tags-changed="updateSelectedTagIds" />
              <!-- no tags warning -->
              <p v-if="errorObject.tags" class="help is-danger">
                {{ errorObject.tags }}
              </p>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button
              class="button"
              :class="{ 'is-loading': submitting }"
              @click="uploadComments"
              style=" border-color:#FF0000"
            >
              Upload
            </button>
            <button class="button" @click="closeUploadModal">
              Cancel
            </button>
            <div v-if="success" class="notification is-success"
            style="color: #F4F6F6;
        background-color:#52BE80;">
              Comment(s) have been submitted successfully.
            </div>
            <div v-if="error" class="notification is-danger"
            style="color: #F4F6F6;
        background-color:#E74C3C;">
              Something went wrong. Please try again later or report it as an
              issue.
            </div>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TagsInput from "../TagsInput";

export default {
  name: "UploadResponseYoutubeComment",
  components: {
    "tags-input": TagsInput
  },
  data() {
    return {
      isActive: false,
      youtubeUrl: "",
      noUrl: false,
      comments: [],
      errorMessage: "",
      selectedComments: [],
      isUploadModalActive: false,
      description: "",
      selectedTrigger: "",
      selectedTriggerName: "",
      selectedTagIds: [],
      submitting: false,
      success: false,
      error: false,
      videoId: "",
      errorObject: {
        description: "",
        tags: "",
        trigger: "",
        comments: ""
      }
    };
  },
  created() {
    this.$store.dispatch("requestTriggerEvents");
    this.$store.dispatch("requestTags");
    this.videoId = "";
  },
  computed: {
    triggerEvents: function() {
      return this.$store.getters.getTriggerEvents;
    },
    tags: function() {
      return this.$store.getters.getTags;
    }
  },
  methods: {
    // gets the comments according to the searchInput
    requestComments() {
      if (!this.youtubeUrl) {
        this.noUrl = true;
        return;
      }
      this.comments = [];
      this.noUrl = false;
      this.isActive = true;
      this.errorMessage = "";
      let url = "/youtube-comments/" + this.getVideoId();
      this.videoId = this.getVideoId();
      axios
        .get(url)
        .then(response => {
          // TODO
          this.comments = response.data;
          this.isActive = false;
        })
        .catch(() => {
          // TODO
          this.isActive = false;
          this.errorMessage =
            "Something went wrong while getting the comments. Please try again later or report an issue.";
        });
    },

    // clears the list of selected comments
    clearSelected() {
      this.selectedComments = [];
    },

    // returns the name of the selected trigger event given its id
    getTriggerName(id) {
      let triggerName = this.triggerEvents.find(trigger => trigger.id === id);
      return triggerName.event_name;
    },

    selectAndUpload(comment) {
      this.selectedComments = [];
      this.selectedComments.push(comment);
      this.isUploadModalActive = true;
    },

    // post comments to the backend to upload
    uploadComments() {
      if (this.checkFields()) {
        let formObject = this.getFormObject();
        this.submitting = true;
        axios({
          method: "post",
          url: "/upload-response",
          data: formObject
        })
          .then(() => {
            this.submitting = false;
            this.error = false;
            this.success = true;
            setTimeout(() => {
              this.$router.go();
            }, 1000);
          })
          .catch(() => {
            this.submitting = false;
            this.success = false;
            this.error = true;
          });
      }
    },
    checkFields() {
      let result = true;
      // comments
      if (this.selectedComments.length < 1) {
        this.errorObject.comments = "Please select (a) comment(s) to upload.";
        result = false;
      } else {
        this.errorObject.comments = "";
      }
      // description
      if (this.description.length < 1) {
        this.errorObject.description = "Please provide a description.";
        result = false;
      } else {
        this.errorObject.description = "";
      }
      // trigger
      if (this.selectedTrigger.length < 1) {
        this.errorObject.trigger = "Please select a trigger event.";
        result = false;
      } else {
        this.errorObject.trigger = "";
      }
      return result;
    },
    getFormObject() {
      let formData = new FormData();
      let trigger_name = this.getTriggerName(this.selectedTrigger);
      let commentEvents = [];
      for (let comment of this.selectedComments) {
        let commentEvent = {
          event_name: comment.content.substring(0, 240),
          description: this.description,
          event_type: "youtube-comment",
          src: this.videoId,
          content: comment.content,
          occurred_at: this.createDateString(comment.published_at),
          author: comment.author,
          trigger_id: this.selectedTrigger,
          trigger_name: trigger_name,
          external_id: comment.comment_id
        };
        commentEvents.push(commentEvent);
      }
      formData.append("events", JSON.stringify(commentEvents));
      formData.append("tags", JSON.stringify(this.selectedTagIds));
      return formData;
    },
    closeUploadModal() {
      this.error = false;
      this.success = false;
      this.isUploadModalActive = false;
    },
    createDateString(dateString) {
      const date = new Date(dateString);
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getUTCFullYear();
      let formattedString = year + "-" + month + "-" + day;
      return formattedString;
    },
    getVideoId() {
      let regex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
      let videoId = this.youtubeUrl.match(regex);
      return videoId[1];
    },
    updateSelectedTagIds(idArray) {
      this.selectedTagIds = idArray;
    }
  }
};
</script>

<style scoped>
.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  margin-right: 5em;
  height: 2em;
}

.iframe-container iframe {
  border: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

/* 4x3 Aspect Ratio */
.iframe-container-4x3 {
  padding-top: 75%;
}

.upload-response-youtube-comment {
  margin-bottom: 5em;
}
</style>
