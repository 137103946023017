<template>
<div>
    <navigation-bar />
    <div class="section">
        <h1 class="is-size-4" style="margin-bottom: 1em;">Browse Trigger Events</h1>
        <div class="columns is-multiline">
            <div class="column is-half"
            v-for="triggerEvent in triggerEvents"
            :key="triggerEvent.id"> 
                <event-card :triggerEvent="triggerEvent"/>
            </div> 
        </div>
    </div>
    <footer-component />
</div>
</template>

<script>
import EventCard from '../components/EventCard.vue';
import NavigationBar from '../components/NavigationBar.vue';
import Footer from "../components/Footer";

export default {
    name: 'Browse',
    components: {
        'event-card': EventCard,
        'navigation-bar': NavigationBar,
        'footer-component': Footer,
    },
    computed: {
        triggerEvents: function(){
            return this.$store.getters.getTriggerEvents;
        }
    },
    created() {
        this.$store.dispatch('requestTriggerEvents');
    },
};
</script>

<style scoped>
    event-card{
        margin-left: 2%;
    }
</style>
