<template>
  <div class="search-result">
    <!-- search info for search results-->

    <!-- event info -->
    <div>
      <section class="section">
        <h1 class="is-size-4">Search result</h1>
        <br />
        
      </section>
    </div>

    <!--list search results-->
    <div v-if="results.length > 0" class="section">
      <h1 class="is-size-4">Results ( {{results.length}} ):</h1>
      <br/>
      <div class="">
          <event-list :eventList="results" /> 
      </div>
    </div>

    <div v-else class="no-result is-size-5">
      <p>
      No event exists for the given search.
      </p>
      <router-link  to="/search"> Try different criterias. </router-link>
    </div>
  </div>
</template>

<script>
import EventList from "./EventList";


export default {
  name: "SearchResult",
  computed: {
    results: function(){
      return this.$store.getters.getResponseEvents;
    }
  },
  methods: {
    updateCurrentEvent(event) {
      this.$store.dispatch("changeCurrentEvent", event);
      this.$store.dispatch("requestTagsByEvent", event.id);
    }
  },
  created() {
    this.$store.dispatch("changeCurrentEvent", {});
    this.$store.dispatch("requestSearchEvents", this.$route.params.queryString);
  },
  components: {
    'event-list': EventList,
  }
};
</script>

<style scoped>
li {
  margin: 1.5em;
}
a :hover {
  background-color: azure;
}
.eventListArea {
  padding: 1.5em;
}
.eventList {
  overflow-y: scroll;
  border-style: solid;
  border-width: 0.1em;
  height: 600px;
  border-color: gainsboro;
  padding: .5em;
}

.search-result{
  padding-bottom: 10em;
  min-height: 1000px;

}

.no-result{
  padding: 50px;
  min-height: 200px;
}

</style>
