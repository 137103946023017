<template>
  <div>
    <navigation-bar />
    <section
      class="hero is-fullheight is-bold"
      style="background-color: white;
      overflow:hidden;"
    >
      <div class="hero-body">
        <!-- Label and Links -->
        <div class="container">
          <h1 class=" is-size-3">
            Emotional Dissonance and the Post-Truth Crisis
          </h1>
          <h1 class=" is-size-3">
            Principal Investigator: Barbara Dancygier
          </h1>
          <br />
          <h2 class="is-size-4">Welcome {{ user.username }}.</h2>

          <br/>

          <h3 class="is-size-4">
            <router-link class="routerLink" to="/browse">
              Browse
            </router-link>
          </h3>

          <h3 class="is-size-4">
            <router-link class="routerLink" to="/search">
              Search
            </router-link>
          </h3>

          <h3 class="is-size-4">
            <router-link
              class="routerLink"
              v-if="!(user.user_role === 'watcher')"
              to="/upload"
            >
              Upload
            </router-link>
          </h3>
        </div>
      </div>
    </section>
    <footer-component />
  </div>
</template>

<script>
import NavigationBar from "../components/NavigationBar";
import Footer from "../components/Footer";

export default {
  name: "Home",
  components: {
    "navigation-bar": NavigationBar,
    "footer-component": Footer,
  },
  computed: {
    user: function() {
      return this.$store.getters.getUser;
    },
  },
};
</script>

<style scoped>
.routerLink {
  color: darkslategrey;
  text-decoration: underline;
}
</style>
