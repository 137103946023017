<template>
  <div>

      {{ /* Navbar */ }}
    <nav
      class="navbar is-size-4"
      role="navigation"
      aria-label="main navigation"
      style="border-bottom: solid 1px black;"
    >
      <div class="navbar-brand">
        <a id="title" class="navbar-item is-size-4" href="/">
          Emodissey
        </a>
        
      </div>

      <div id="navbarItems" class="navbar-menu">
        <div class="navbar-end">
          <div class="navbar-item">
            
          </div>
        </div>
      </div>
    </nav>

  
     <div>
      <div class="center">
        <!-- Label and Links -->
        <div class="container">
          <h1 class="title is-size-4">
            Emotional Dissonance and the Post-Truth Crisis
          </h1>
          <h1 class="subtitle is-size-4">
            Principal Investigator: Barbara Dancygier
          </h1>

           <div style="min-height: 30em;">
    <login />
    </div>
        </div>
       


      </div>
    
     </div>
     <footer-component />
  </div>
</template>

<script>
import Login from "../components/Login";
import Footer from "../components/Footer";

export default {
  name: "Welcome",
  components: {
    'login': Login,
    'footer-component': Footer,
  },
  data(){
      return {
          isLoginActive: false,
      }
  },
  methods: {
      /**
       * Activates/deactivates login widget.
       * 
       */
      toggleLogin(){
          this.isLoginActive = !this.isLoginActive;
      }
  }
};
</script>

<style scoped>
 #title:hover{
     color:gold;
 }
#loginButton:hover{
  background-color: grey;
}

.center {
  margin: auto;
  width: 50%;
  padding: 10px;
  margin-top: 20vh;
  align-content: center;
  }
</style>
