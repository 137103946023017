<template>
  <div>
    <navigation-bar />
    <div class="section"> 
      <h1 class="is-size-4"> Upload a Response Event  >
           </h1>
       <h1 class="is-size-4"> Search & Upload Tweets
           </h1>
   </div>
   <upload-response-tweets />
   <footer-component />
  </div>
</template>

<script>

import NavigationBar from "../../components/NavigationBar.vue";
import UploadResponseTweets from "../../components/Response/UploadResponseTweets";
import Footer from '../../components/Footer';


export default {
  name: "UploadResponseTweets",
  components: {
    "navigation-bar": NavigationBar,
    "upload-response-tweets": UploadResponseTweets,
    'footer-component': Footer,
  }
};
</script>
