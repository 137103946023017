<template>
  <div class="box login">
    <h1 class="is-size-4"> Login </h1>
    <br />
        <div class="field">
          <label class="label">email</label>
          <div class="control">
            <input class="input" type="email" placeholder="example@example.com"
            v-on:keydown.enter="loginUser()" v-model="email" />
          </div>
          <p v-if="emailError" class="help is-danger">
            Enter email
          </p>
        </div>

          <div class="field">
            <label class="label">password</label>
            <div class="control">
              <input class="input" type="password" placeholder="******" v-model="password"
              @keydown.enter="loginUser()"/>
            </div>
            <p v-if="passwordError" class="help is-danger">
            Enter password
          </p>
          </div>
          <div class="field">
            <div class="control">
              <div class="buttons is-right" v-if="!loginSuccess">
                <div class="button" v-bind:class="{ 'is-loading': isLoggingIn }"
                @click="loginUser()">
                  Login
                </div>
              </div>
            </div>
          </div>
        
        <div v-if="loginSuccess" class="notification is-success"
        style="color: #F4F6F6;
        background-color:#52BE80;">
          Login successful.
        </div>
        <div v-if="loginError" class="notification is-danger"
        style="color: #F4F6F6;
        background-color:#E74C3C;">
          {{loginError}}
        </div>
  </div>
</template>

<script>
import axios from 'axios'


export default {
  name: "Login",
  data() {
    return {
    email: "",
    password: "",
    loginSuccess: false,
    loginError: "",
    user: "",
    isLoggingIn: false,
    emailError: false,
    passwordError: false,
  }
  },
  methods:{
    loginUser(){
      if(this.isLoggingIn) return; // wait till this one resolves
      this.isLoggingIn = true;
      // check if all fields are complete and valid.
      if(!this.checkFields()){
        this.isLoggingIn = false;
        return;
      } 
      let data = {
        email: this.email,
        password: this.password
      }
      this.loginSuccess = false;
      this.loginError = false;
      axios.post('/login', data)
      .then(response => {
        this.loginSuccess = true;
        this.isLoggingIn = false;
        this.user = response.data.user;
        this.$store.dispatch('setUser', response.data.user);
        setTimeout(() => {
          this.$router.push('/home'); 
        }, 1000);
      })
      .catch(error => {
        this.isLoggingIn = false;
        this.loginError = error.response.data.message;
      })
    },
    checkFields(){
      let result = true;
      if(!this.email) {
        result = false;
        this.emailError = "Enter email";
      } else {
        this.emailError = "";
      }
      if(!this.password) {
        result = false;
        this.passwordError = "Enter password";
      } else {
        this.passwordError = "";
      }
      return result;
    },

  }
};
</script>


<style scoped>
  .login {
    
    width: 30em;
    right:0em;
    
    position: relative;
    margin-bottom:5em;
  }
</style>