<template>
  <div>
    <div class="section">
      <h1 class="is-size-4"> Upload a Response Event </h1>
      <div class="link-fields">
      <div class="columns is-multiline">
        <div class="column is-half">
          <div class="box link-box"  @click="$router.push('/upload/response/file')">
              <p class="has-text-dark is-size-4">image, video or a pdf file</p>
          </div>
        
        </div>

        <div class="column is-half">
          <div class="box link-box"  @click="$router.push('/upload/response/tweet')">
              <p class="has-text-dark is-size-4">search & upload a tweet</p>
          </div>
        
        </div>

        <div class="column is-half">
          <div class="box link-box"  @click="$router.push('/upload/response/tweet-link')">
              <p class="has-text-dark is-size-4">upload a tweet via url</p>
          </div>
        
        </div>

        <div class="column is-half">
          <div class="box link-box"  @click="$router.push('/upload/response/youtube')">
              <p class="has-text-dark is-size-4">upload a youtube video via url</p>
          </div>
        
        </div>

        <div class="column is-half">
          <div class="box link-box"  @click="$router.push('/upload/response/youtube-comments')">
              <p class="has-text-dark is-size-4">get & upload youtube comments</p>
          </div>
        
        </div>

        <div class="column is-half">
          <div class="box link-box"  @click="$router.push('/upload/response/youtube-comment-manual')">
              <p class="has-text-dark is-size-4">upload a youtube comment via url</p>
          </div>
        
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadTrigger",
  
};
</script>

<style scoped>
  .link-box{
    width:90%;
    text-align:center; 
    display: inline-block;
    cursor: pointer;
    min-height: 10em;
    overflow: hidden;
  }
  .link-box:hover{
    background-color: #F4F6F6;
  }
  .link-fields{
  width: 80%;
  margin-left:  5%;
  margin-right: 5%;
  padding: 5%;
}
</style>
