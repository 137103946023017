<template>
  <div class="upload-tweet-link">
    <!-- Search Field -->
    <div class="section">
      <div class="field" style="margin-right:20em">
        <div class="control">
          <label> Link for the tweet</label>
          <input
            class="input is-info"
            type="text"
            placeholder="https://twitter.com/POTUS44/status/819044196371800065"
            v-model="searchInput"
            v-on:keyup.enter="getTweet()"
          />
        </div>
        <div class="control">
          <div class="buttons is-right">
            <button
              class="button is-info"
              v-bind:class="{ 'is-loading': isActive }"
              @click="getTweet()"
            >
              Get tweet
            </button>
          </div>
        </div>
        <div class="control">
          <p v-if="noSearchInput" class="help is-danger">
            Please provide a link.
          </p>
          <p v-if="noValidLink" class="help is-danger">
            Please provide a valid twitter url.
          </p>
          <p v-if="getTweetError" class="help is-danger">
            {{ getTweetError }}
          </p>
        </div>
      </div>
    </div>
    <br />
    <!-- upload section -->
    <div v-if="tweet">
      <div class="columns">
        <div class="column is-half">
          <div class="section">
            <h1 class="is-size-5">Tweet:</h1>
            <br />
            <tweet-card :id="tweet.id" :key="tweet.id" />
          </div>
        </div>

        <div class="column is-half is-size-5">
          <div class="section is-size-5">
            <h1 class="is-size-5">Upload this tweet:</h1>
            <br />
            <div class="box">
              <!-- Form -->
              <div class="field">
                <label class="label is-medium">Description</label>
                <div class="control">
                  <input
                    class="input"
                    type="text"
                    v-model="description"
                    placeholder="Enter a description for tweet(s)"
                    maxlength="1000"
                  />
                </div>
                <!-- no description warning -->
                <p v-if="errorObject.description" class="help is-danger">
                  {{ errorObject.description }}
                </p>
              </div>

              <!-- Trigger Input -->
              <div class="field">
                <label class="label is-medium">Select a trigger event</label>
                <div class="control">
                  <div class="select is-medium">
                    <select v-model="selectedTrigger">
                      <option
                        v-for="trigger in triggerEvents"
                        :key="trigger.id"
                        :id="trigger.id"
                        :value="trigger.id"
                      >
                        {{ trigger.event_name }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- no trigger warning -->
                <p v-if="errorObject.trigger" class="help is-danger">
                  {{ errorObject.trigger }}
                </p>
              </div>

              <!-- Tags Input -->
              <div class="field is-medium" style="margin-bottom:5em;">
                <label class="label ">Select tags</label>
                <tags-input :tags="tags" @tags-changed="updateSelectedTagIds" />
                <!-- no tags warning -->
                <p v-if="errorObject.tags" class="help is-danger">
                  {{ errorObject.tags }}
                </p>
              </div>

              <div class="control">
                <div class="buttons is-right">
                  <button
                    class="button is-info is-right"
                    :class="{ 'is-loading': submitting }"
                    @click="uploadTweet"
                  >
                    Upload
                  </button>
                </div>
              </div>
              <div v-if="success" class="notification is-success"
              style="color: #F4F6F6;
        background-color:#52BE80;">
                Tweet(s) have been submitted successfully.
              </div>
              <div v-if="uploadError" class="notification is-danger"
              style="color: #F4F6F6;
        background-color:#E74C3C;">
                Something went wrong. Please try again later or report it as an
                issue.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Tweet } from "vue-tweet-embed";
import axios from "axios";
import TagsInput from "../TagsInput";

export default {
  name: "UploadResponseTweetLink",
  data() {
    return {
      searchInput: "",
      noSearchInput: false,
      isActive: false,
      tweetId: "",
      noValidLink: false,
      tweet: "",
      description: "",
      selectedTrigger: "",
      selectedTagIds: [],
      submitting: false,
      success: false,
      uploadError: false,
      getTweetError: false,
      errorObject: {
        description: "",
        trigger: "",
        tags: "",
        tweet: ""
      }
    };
  },
  components: {
    "tweet-card": Tweet,
    "tags-input": TagsInput
  },
  created() {
    this.$store.dispatch("requestTriggerEvents");
    this.$store.dispatch("requestTags");
  },
  computed: {
    triggerEvents: function() {
      return this.$store.getters.getTriggerEvents;
    },
    tags: function() {
      return this.$store.getters.getTags;
    }
  },
  methods: {
    getTweet() {
      if (!this.checkFields()) {
        return;
      }
      // safe to get tweet info
      let id = this.getTweetId();
      this.tweetId = id;
      this.getTweetError = false;
      this.tweet = "";
      let reqUrl = "/tweet/" + this.tweetId;
      axios
        .get(reqUrl)
        .then(response => {
          this.tweet = response.data;
        })
        .catch(() => {
          this.getTweetError = "Cannot find the tweet.";
        });
    },

    getTweetId() {
      let reg = /([0-9])\d+/;
      let id = this.searchInput.match(reg);
      return id[0];
    },

    isValidUrl() {
      let reg = /(?:http:\/\/)?(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/;
      let urlMatch = this.searchInput.match(reg);
      return urlMatch !== null;
    },

    checkFields() {
      let result = true;
      if (!this.searchInput) {
        this.noSearchInput = true;
        result = false;
        return;
      } else {
        this.noSearchInput = false;
        if (!this.isValidUrl()) {
          this.noValidLink = true;
          result = false;
          return;
        } else {
          this.noValidLink = false;
          return result;
        }
      }
    },
    uploadTweet() {
      if (!this.checkUploadFields()) {
        return;
      }
      let formObject = this.getFormObject();
      this.submitting = true;
      axios({
        method: "post",
        url: "/upload-response",
        data: formObject
      })
        .then(() => {
          this.submitting = false;
          this.uploadError = false;
          this.success = true;
          setTimeout(() => {
            this.$router.go();
          }, 1000);
        })
        .catch(() => {
          this.submitting = false;
          this.success = false;
          this.uploadError = true;
        });
    },

    checkUploadFields() {
      let result = true;
      // tweets
      if (!this.tweet) {
        this.errorObject.tweet = "Cannot find tweet to upload";
        result = false;
      } else {
        this.errorObject.tweets = "";
      }
      // description
      if (this.description.length < 1) {
        this.errorObject.description = "Please provide a description.";
        result = false;
      } else {
        this.errorObject.description = "";
      }
      // trigger
      if (this.selectedTrigger.length < 1) {
        this.errorObject.trigger = "Please select a trigger event.";
        result = false;
      } else {
        this.errorObject.trigger = "";
      }
      return result;
    },

    createDateString(dateString) {
      const date = new Date(dateString);
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getUTCFullYear();
      let formattedString = year + "-" + month + "-" + day;
      return formattedString;
    },

    // returns the name of the selected trigger event given its id
    getTriggerName(id) {
      let triggerName = this.triggerEvents.find(trigger => trigger.id === id);
      return triggerName.event_name;
    },

    getFormObject() {
      let formData = new FormData();
      let trigger_name = this.getTriggerName(this.selectedTrigger);
      let tweetEvents = [];
      let tweetEvent = {
        event_name: this.tweet.text.substring(0, 240),
        description: this.description,
        event_type: "tweet",
        src: this.tweet.url,
        content: this.tweet.text,
        occurred_at: this.createDateString(this.tweet.created_at),
        author: this.tweet.user,
        trigger_id: this.selectedTrigger,
        trigger_name: trigger_name,
        external_id: this.tweet.id
      };
      tweetEvents.push(tweetEvent);

      formData.append("events", JSON.stringify(tweetEvents));
      formData.append("tags", JSON.stringify(this.selectedTagIds));
      return formData;
    },

    updateSelectedTagIds(idArray) {
      this.selectedTagIds = idArray;
    }
  }
};
</script>

style
<style scoped>
.upload-tweet-link {
  margin-bottom: 5em;
}
</style>
