<template>
<div>
    <navigation-bar />
    <div class="columns">
        
        <div class="column is-half" style="border-right: solid 1px darkgrey;">
            <trigger-event-info/>
        </div>

        <div class="column is-half">
            <event-info v-if="isEventClicked" />
        </div>
    </div>
    <footer-component />
</div>
</template>

<script>
import NavigationBar from '../components/NavigationBar';
import TriggerEventInfo from '../components/TriggerEventInfo';
import EventInfo from '../components/EventInfo';
import Footer from "../components/Footer";

export default {
    name: 'Trigger',
    components: {
        'navigation-bar': NavigationBar,
        'trigger-event-info': TriggerEventInfo,
        'event-info': EventInfo,
        'footer-component': Footer,
    },
    computed: {
        isEventClicked: function() {
            return !(this.$store.getters.IsCurrentEventEmpty);
        }
    }
}
</script>