<template>
  <div>
    <article
      class="media issue-box"
      style="border: solid 0.1em gainsboro; margin: 1em;"
    >
      <figure class="media-left" style="margin: 0.5em; max-width: 15em;" >
        <p class="is-large">
          id: {{ issue.id }}
        </p>
        <p class="is-large">
          {{issue_date}}
        </p>
      </figure>
      <div
        class="media-content"
        style=" border-right:solid 0.1em gainsboro; 
        border-left:solid 0.1em gainsboro;"
      >
        <div class="content" style="margin: 0.5em;">
          <p>
            <strong>{{ issue.username }}</strong>
          </p>
          <p>
            {{ issue.issue_message }}
          </p>
          <p>
            path: {{ issue.path_string }}
          </p>
        </div>
      </div>
      <div
        class="media-right"
        style="display: block; margin: auto;
        "
      >
        <div style="margin: 0.5em;">
          <a style="color: lightcoral" @click="toggleDeleteModal">
            <font-awesome-icon :icon="['far', 'trash-alt']" />
          </a>
        </div>
      </div>
    </article>

    <!-- Delete modal -->
    <div class="modal" :class="{'is-active': isDeleteModalActive}">
        <div class="modal-background" style="background-color: rgb(231, 76, 60, 0.8);"
        @click="toggleDeleteModal"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title" >Delete submitted issue</p>
            <button class="delete" aria-label="close"
            @click="toggleDeleteModal"></button>
          </header>
          <section class="modal-card-body">
            <div class="content">
                <p>
                    Are you sure you want to delete this issue submission?
                </p>
                <p> 
                  <em>
                  {{issue.issue_message}}
                  </em>
                </p>
            </div>

          </section>
          <footer class="modal-card-foot">
            <div v-if="!isDeleteSuccess && !isDeleteFailure">
            <button class="button" :class="{'is-loading': isDeleting}"
            @click="deleteIssue">Yes</button>
            <button class="button" @click="toggleDeleteModal">No, cancel</button>
            </div>
            <div v-if="isDeleteSuccess" class="notification is-success"
              style="color: #F4F6F6;
        background-color:#52BE80;">
                The issue has been deleted.
              </div>
              <div v-if="isDeleteFailure" class="notification is-danger"
              style="color: #F4F6F6;
        background-color:#E74C3C;">
                Something went wrong.
              </div>
          </footer>
        </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios';


export default {
  name: "IssueItem",
  data(){
    return {
      isDeleteModalActive: false,
      isDeleteSuccess: false,
      isDeleteFailure: false,
      isDeleting: false,
    }
  },
  props: {
      issue: {
          type: Object,
      }
  },
  computed: {
      issue_date(){
          let issueDate = new Date(this.issue.posted_at);
          return issueDate;
      }
  },
  methods: {
    deleteIssue(){
      this.resetDeleteNotifications();
      this.isDeleting = true;
      let deleteInfoObject = {
        issue_id: this.issue.id,
      };
      let url = "/delete-issue";
      axios.post(url, deleteInfoObject)
      .then(() => {
        // delete issue success
        this.isDeleting = false;
        this.isDeleteSuccess = true;
        this.refreshIssues();

      })
      .catch(() => {
        // delete issue failure
        this.isDeleting = false;
        this.isDeleteFailure = true;
        this.refreshIssues();
      });

    },

    resetDeleteNotifications(){
      this.isDeleteSuccess = false;
      this.isDeleteFailure = false;
    },

    toggleDeleteModal(){
      this.isDeleteModalActive = !this.isDeleteModalActive;
    },

    refreshIssues(){
      setTimeout(() => {
        this.$router.go();
      }, 1000)
    }
  }
};
</script>
