<template>
  <div class="event-box">
    <article class="media">
      <figure class="media-left">
        <p class="image is-64x64">
          <img
            v-if="trigger_event.image_src"
            :src="trigger_event.image_src"
            style="width:64px; height:64px;"
          />
          <img v-else src="https://bulma.io/images/placeholders/128x128.png" />
        </p>
      </figure>
      <div class="media-content">
        <div class="content">
          <p>
            <strong>{{ trigger_event.event_name }}</strong>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <small>{{ event_date }}</small>
            <br />
            {{ trigger_event.description }}
            <br />
            number of responses: {{ number_of_responses }}
          </p>
        </div>
      </div>
      <div class="media-right">
        <a class="not-active level-item has-text-dark" @click="toggleEditModal()">
          <font-awesome-icon :icon="['far', 'edit']" />
        </a>
        <br />
        <a class="level-item has-text-dark" @click="toggleDeleteModal()" >
          <font-awesome-icon :icon="['far', 'trash-alt']" />
        </a>
      </div>
    </article>

    <!-- Delete Modal -->
    <div class="modal" :class="{'is-active': isDeleteModalActive}">
        <div class="modal-background" style="background-color: rgb(231, 76, 60, 0.8);"
        @click="toggleDeleteModal"></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title" >Delete Trigger Event</p>
            <button class="delete" aria-label="close"
            @click="toggleDeleteModal"></button>
          </header>
          <section class="modal-card-body">
            <div class="content">
                <p>
                    Are you sure you want to delete this trigger event?
                </p>
                <p> 
                  <em>
                  {{trigger_event.event_name}}
                  </em>
                </p>
            </div>

          </section>
          <footer class="modal-card-foot">
            <div v-if="!isDeleteSuccess && !isDeleteFailure">
            <button class="button" :class="{'is-loading': isDeleting}"
            @click="deleteTrigger">Yes</button>
            <button class="button" @click="toggleDeleteModal">No, cancel</button>
            </div>
            <div v-if="isDeleteSuccess" class="notification is-success"
              style="color: #F4F6F6;
        background-color:#52BE80;">
                The trigger event has been deleted.
              </div>
              <div v-if="isDeleteFailure" class="notification is-danger"
              style="color: #F4F6F6;
        background-color:#E74C3C;">
                Something went wrong.
              </div>
          </footer>
        </div>
      </div>
  </div>
</template>

<script>
import axios from 'axios';


export default {
  name: "EventEditDeleteCard",
  data() {
    return {
      isEditModalActive: false,
      isDeleteModalActive: false,
      isDeleteSuccess: false,
      isDeleteFailure: false,
      isDeleting: false,

    };
  },
  props: {
    trigger_event: {
      type: Object
    },
    number_of_responses: {
      type: Number
    }
  },
  methods: {
    toggleEditModal() {
      this.isEditModalActive = !this.isEditModalActive;
    },
    toggleDeleteModal() {
      this.isDeleteModalActive = !this.isDeleteModalActive;
    },
    editTrigger() {
      // TODO
    },


    /**
     * Sends a POST request to delete the trigger event.
     * Changes the labels isDeleting, isDeleteSuccess and 
     * isDeleteFailure after receiving the response so that
     * user is informed.
     */
    deleteTrigger() {
      this.isDeleteSuccess = false;
      this.isDeleteFailure = false;
      this.isDeleting = true;
      let url = "/delete-trigger/" + this.trigger_event.id;
      axios.post(url)
      .then(() => {
          // successful delete
          this.isDeleting = false;
          this.isDeleteSuccess = true;
          setTimeout(() => {
              this.$router.go();
          }, 1000);
      })
      .catch(() => {
          this.isDeleting = false;
          this.isDeleteFailure = true;
      });
    }
  },
  computed: {
    event_date() {
      let date = new Date(this.trigger_event.occurred_at);
      return date.toDateString();
    }
  }
};
</script>

<style scoped>
.event-box {
  border: solid 1px gainsboro;
  padding: 1em;
  margin-right: 10px;
  margin-bottom: 1em;
  height: 7em;
}
</style>
