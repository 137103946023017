<template>
  <div class="trigger-info">
    <!-- metadata for the trigger -->
    <section style="margin:2em;">
      <h1 class="is-size-4">Trigger event</h1>
      <br />
      <br />
      <h2 class="is-size-4">{{ trigger.event_name }}</h2>
      <h3 class="is-size-5"> <em> {{ trigger.description }} </em></h3>
      <br />
      
      <br />
      <!-- content will go here -->
      <!-- Image responseEvent -->
      <div v-if="trigger.event_type == 'image'">
        <img style="display:block; margin:10 auto; " :src="trigger.src" />
      </div>

      <!-- Pdf responseEvent -->
      <div v-if="trigger.event_type == 'pdf'">
        <p class="is-size-5" style="display:block; margin: 0 auto;">
          <br />
          <a class="is-size-5" :href="trigger.src" target="_blank">See this pdf file in a new tab.</a>
          <br />
        </p>
      </div>

      <!-- Text responseEvent -->
      <div v-if="trigger.event_type == 'text'">{{ trigger.content }}</div>

      <!-- Video responseEvent -->
      <div ref="videoDiv" v-if="trigger.event_type == 'video'">
        <video style="display:block; margin: 0 auto;" controls width="90%">
          <source :src="trigger.src" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </section>


    <!-- COMMENTS COMPONENT  -->
    <section style="margin:2em"> 
    <trigger-comments :comments="triggerComments" 
    :parent_id="trigger.id" 
    :isTriggerComment="true"
    v-on:newComment="requestComments()"
    :key="trigger.id"
    v-if="user_role !== 'watcher'"/>
    </section>

    <section style="margin:2em">
      <!--responseEvent event list-->
      <br>
      <h1 class="is-size-4">Response events for this event ({{responseEvents.length}}):</h1>

      <!--list search results-->
      <div class="section" v-if="responseEvents.length > 0">
        <event-list :eventList="responseEvents"> </event-list>
      </div>

    </section>
  </div>
</template>

<script>
import EventList from './EventList';
import Comments from './Comments';
import axios from 'axios';

export default {
  name: "TriggerEventInfo",
  watch: {
    '$route' (to, from) {
      this.requestComments();
    }
  },
  components: {
    'event-list': EventList,
    'trigger-comments': Comments, 
  },
  computed: {
    trigger: function() {
      return this.$store.getters.getCurrentTrigger;
    },
    responseEvents: function() {
      return this.$store.getters.getTriggerResponses;
    },
    user_role: function(){
      return this.$store.getters.getUserRole;
    },
    triggerComments: function(){
      return this.$store.getters.getTriggerComments;
    }
  },
  created() {
    let triggerId = this.$route.params.triggerId;
    this.$store.dispatch("requestTriggerInformation", triggerId);
    this.$store.dispatch("requestTriggerResponses", triggerId);
    this.$store.dispatch("changeCurrentEvent", {});
    this.requestComments();
  },
  methods: {
    updateCurrentEvent(event) {
      this.$store.dispatch("changeCurrentEvent", event);
      this.$store.dispatch("requestTagsByEvent", event.id);
    },

    requestComments(){
    let triggerId = this.$route.params.triggerId;
    this.$store.dispatch('resetTriggerComments');
    this.$store.dispatch('requestTriggerComments', triggerId);
  }
  }
};
</script>

<style scoped>
.trigger-info{
  padding-bottom: 5em;
  min-height: 900px;
}
</style>
