<template>
    <section class="hero is-medium">
        <div class="hero-body">
        <div class="container">
            <h1 class="title">
                Not found.
            </h1>
        </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'NotFoundComponent',
}
</script>
