<template>
  <div class="upload-manual-comment">
    <!-- Search Field -->
    <div class="section">
      <div class="field" style="margin-right:10em; min-width:15em;">
        <h1 class="is-size-5">Link for the youtube video</h1>
        <div class="control">
          <input
            class="input"
            type="text"
            placeholder="https://youtu.be/WWS747M7UnQ"
            v-model="youtubeUrl"
            style=" border-color:#FF0000"
            @keydown.enter="getVideo()"
          />
          <p v-if="noInput" class="help is-danger">
            Please provide something to search.
          </p>
          <p v-if="noValidUrl" class="help is-danger">
            Please provide a valid youtube video url.
          </p>
        </div>
      </div>

      <div class="field" style="margin-right:10em; min-width:15em;">
        <div class="control">
          <div class="buttons is-right">
            <button
              class="button"
              v-bind:class="{ 'is-loading': isGettingVideo }"
              @click="getVideo()"
              style=" border-color:#FF0000; margin-top:1em;"
            >
              Get the video
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- VIDEO -->
    <div class="section"></div>

    <div class="section" v-if="videoId">
      <div class="columns">
        <div class="column is-half">
          <h1 class="is-size-5">Video:</h1>
          <br />

          <div class="iframe-container">
            <iframe
              :src="'https://www.youtube.com/embed/' + videoId"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            >
            </iframe>
          </div>
        </div>

        <div class="column is-half">
          <h1 class="is-size-5">Comment:</h1>
          <br />
          <div class="box" style="margin-right: auto; max-width:50em;">
            <article class="media">
              <figure class="media-left"></figure>

              <div class="media-content">
                <div class="content">
                  <!-- AUTHOR FIELD -->
                  <div class="field">
                    <label class="label">Author</label>
                    <div class="control">
                      <input
                        class="input"
                        type="text"
                        v-model="author"
                        placeholder="author"
                      />
                    </div>
                    <!-- no description warning -->
                    <p v-if="errorObject.author" class="help is-danger">
                      {{ errorObject.author }}
                    </p>
                  </div>

                  <div class="field">
                    <label class="label"> Comment content</label>
                    <div class="control">
                      <input
                        class="input"
                        type="text"
                        v-model="comment"
                        placeholder="copy & paste the comment"
                      />
                    </div>
                    <!-- no description warning -->
                    <p v-if="errorObject.comment" class="help is-danger">
                      {{ errorObject.comment }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="media-right"></div>
            </article>
          </div>

          <!-- UPLOAD FIELDS -->

          <h1 class="is-size-5">Upload this comment:</h1>
          <br />
          <!-- Upload Info Fields -->
          <div class="box">
            <!-- Form -->
            <div class="field">
              <label class="label is-medium">Description</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model="description"
                  placeholder="Enter a description for comment(s)"
                  maxlength="1000"
                />
              </div>
              <!-- no description warning -->
              <p v-if="errorObject.description" class="help is-danger">
                {{ errorObject.description }}
              </p>
            </div>

            <!-- Trigger Input -->
            <div class="field">
              <label class="label is-medium">Select a trigger event</label>
              <div class="control">
                <div class="select is-medium">
                  <select v-model="selectedTrigger">
                    <option
                      v-for="trigger in triggerEvents"
                      :key="trigger.id"
                      :id="trigger.id"
                      :value="trigger.id"
                    >
                      {{ trigger.event_name }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- no trigger warning -->
              <p v-if="errorObject.trigger" class="help is-danger">
                {{ errorObject.trigger }}
              </p>
            </div>

            <!-- Tags Input -->
            <div class="field " style="margin-bottom: 5em;">
              <label class="label is-medium">Select tags</label>
              <tags-input :tags="tags" @tags-changed="updateSelectedTagIds" />
              <!-- no tags warning -->
              <p v-if="errorObject.tags" class="help is-danger">
                {{ errorObject.tags }}
              </p>
            </div>

            <!-- Date Input -->
            <div class="field">
              <label class="label is-size-5">Enter date</label>
              <functional-calendar
                class="date-picker"
                :is-date-picker="true"
                :change-month-function="true"
                :change-year-function="true"
                :dateFormat="'yyyy/mm/dd'"
                :is-modal="true"
                v-on:dayClicked="changeDate"
              >
              </functional-calendar>
              <!-- no date warning -->
              <p v-if="errorObject.eventDate" class="help is-danger">
                {{ errorObject.eventDate }}
              </p>
            </div>

            <div class="control">
              <div class="buttons is-right">
                <button
                  class="button"
                  v-bind:class="{ 'is-loading': isUploading }"
                  @click="uploadComment"
                  style=" border-color:#FF0000; margin-top:1em;"
                >
                  Upload
                </button>
              </div>
            </div>
            <div class="control">
              <div
                v-if="success"
                class="notification is-success"
                style="color: #F4F6F6;
        background-color:#52BE80;"
              >
                The comment has been submitted successfully.
              </div>
              <div
                v-if="postReqError"
                class="notification is-danger"
                style="color: #F4F6F6;
        background-color:#E74C3C;"
              >
                Something went wrong while uploading the comment. Please try
                again later or report it as an issue.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TagsInput from "../TagsInput";
import { FunctionalCalendar } from 'vue-functional-calendar';

export default {
  name: "UploadYoutubeCommentManual",
  components: {
    "tags-input": TagsInput,
    "functional-calendar": FunctionalCalendar,
  },
  data() {
    return {
      youtubeUrl: "",
      noInput: false,
      noValidUrl: false,
      videoId: "",
      isGettingVideo: false,
      description: "",
      selectedTrigger: "",
      selectedTagIds: [],
      postReqError: "",
      isUploading: "",
      author: "",
      comment: "",
      eventDate: "",
      success: "",
      yyyy: "",
      mm: "",
      dd: "",
      errorObject: {
        tags: "",
        description: "",
        trigger: "",
        author: "",
        comment: "",
        date: "",
      },
    };
  },
  created() {
    this.$store.dispatch("requestTriggerEvents");
    this.$store.dispatch("requestTags");
    this.videoId = "";
  },
  computed: {
    triggerEvents: function() {
      return this.$store.getters.getTriggerEvents;
    },
    tags: function() {
      return this.$store.getters.getTags;
    },
  },
  methods: {
    checkSearchFields() {
      let result = true;
      this.noInput = false;
      this.noValidUrl = false;

      if (!this.youtubeUrl) {
        this.noInput = true;
        result = false;
      } else {
        if (!this.checkValidUrl()) {
          this.noValidUrl = true;
          result = false;
        }
      }
      return result;
    },
    checkValidUrl() {
      let regex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
      let match = this.youtubeUrl.match(regex);
      return match !== null;
    },
    getVideo() {
      if (!this.checkSearchFields()) {
        return;
      }
      this.videoId = this.getVideoId();
    },
    uploadComment() {
      if (!this.checkUploadFields()) {
        return;
      }
      let formObject = this.getFormObject();
      this.isUploading = true;
      this.postReqError = false;
      this.success = false;
      axios({
        method: "post",
        url: "/upload-response",
        data: formObject,
      })
        .then(() => {
          this.isUploading = false;
          this.success = true;
          setTimeout(() => {
            this.$router.go();
          }, 1000);
        })
        .catch(() => {
          this.isUploading = false;
          this.error = true;
        });
    },
    getVideoId() {
      let regex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
      let videoId = this.youtubeUrl.match(regex);
      return videoId[1];
    },
    checkUploadFields() {
      let result = true;
      if (!this.author) {
        this.errorObject.author = "Please enter the name of the author.";
        result = false;
      } else {
        this.errorObject.author = "";
      }
      if (!this.comment) {
        this.errorObject.comment = "Please enter the comment.";
        result = false;
      } else {
        this.errorObject.comment = "";
      }
      if (!this.description) {
        this.errorObject.description = "Please enter description.";
        result = false;
      } else {
        this.errorObject.description = "";
      }
      if (this.selectedTrigger.length < 1) {
        this.errorObject.trigger = "Please select a trigger event.";
        result = false;
      } else {
        this.errorObject.trigger = "";
      }

      // eventDate
      if (this.eventDate.length < 1) {
        this.errorObject.eventDate = "Please pick a date.";
      } else {
        this.errorObject.eventDate = "";
      }

      return result;
    },
    getFormObject() {
      let formData = new FormData();
      let trigger_name = this.getTriggerName(this.selectedTrigger);
      let commentEvents = [];

      let commentEvent = {
        event_name: this.comment.substring(0, 240),
        description: this.description,
        event_type: "youtube-comment",
        src: this.videoId,
        content: this.comment,
        occurred_at: this.eventDate,
        author: this.author,
        trigger_id: this.selectedTrigger,
        trigger_name: trigger_name,
        external_id: "NULL",
      };
      commentEvents.push(commentEvent);
      formData.append("events", JSON.stringify(commentEvents));
      formData.append("tags", JSON.stringify(this.selectedTagIds));
      return formData;
    },
    // returns the name of the selected trigger event given its id
    getTriggerName(id) {
      let triggerName = this.triggerEvents.find((trigger) => trigger.id === id);
      return triggerName.event_name;
    },

    updateSelectedTagIds(idArray) {
      this.selectedTagIds = idArray;
    },

    // updates the selected event date based on date-picker event
    changeDate(newDate) {
      this.eventDate = newDate.date.split("/").join("-");
      console.log(this.eventDate);
    },
  },
};
</script>

<style scoped>
.iframe-container {
  overflow: hidden;
  padding-top: 56.25%;
  position: relative;
  margin-right: 5em;
  height: 2em;
}

.iframe-container iframe {
  border: 0;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

/* 4x3 Aspect Ratio */
.iframe-container-4x3 {
  padding-top: 75%;
}

.upload-manual-comment {
  margin-bottom: 5em;
}

.date-box {
  width: 50px;
}
</style>
