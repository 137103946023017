<template>
  <a class="has-text-dark" @click="updateCurrentEvent(event)" >
    <article class="media" href="/" :class="{'selected': isSelected}">
      <figure v-if="event.event_type === 'pdf'" class="media-left" >
        <p class="image is-32x32">
          <font-awesome-icon :icon="['far', 'file-pdf']" />
          
        </p>
      </figure>

      <figure v-if="event.event_type === 'text'" class="media-left">
        <p class="image is-32x32">
          <font-awesome-icon :icon="['fas', 'align-justify']" />
          
        </p>
      </figure>

      <figure v-if="event.event_type === 'youtube-comment'" class="media-left">
        <p class="image is-32x32">
          <font-awesome-icon :icon="['fab', 'youtube-square']" />
          
        </p>
      </figure>

      <figure v-if="event.event_type === 'youtube'" class="media-left">
        <p class="image is-32x32">
          <font-awesome-icon :icon="['fab', 'youtube']" />
          
        </p>
      </figure>

      <figure v-if="event.event_type === 'tweet'" class="media-left">
        <p class="image is-32x32">
          <font-awesome-icon :icon="['fab', 'twitter']" />
         
        </p>
      </figure>

      <figure v-if="event.event_type === 'image'" class="media-left">
        <p class="image is-32x32">
          <font-awesome-icon :icon="['far', 'image']" />
         
        </p>
      </figure>

      <figure v-if="event.event_type === 'video'" class="media-left">
        <p class="image is-32x32">
          <font-awesome-icon :icon="['fas', 'film']" />
          
        </p>
      </figure>

      <div class="media-content">
        <div class="content">
          <p>
            <strong>{{ event.event_name }}</strong>
            <small>  &nbsp;&nbsp; </small>
            <small
              >{{
                event_date
              }}
            </small>
            <br />
            {{ event.description }}
          </p>
        </div>
      </div>
      <div class="media-right"></div>
    </article>
  </a>
</template>

<script>

export default {
    name: 'EventListItem',
    methods: {
        updateCurrentEvent(event) {
            this.$store.dispatch('changeCurrentEvent', event);
            this.$store.dispatch('requestTagsByEvent', event.id);
            window.scrollTo(0,0);
        }
    },
    props:{
        event: Object,
    },
    computed: {
      event_date : function() {
      let timestamp = new Date(this.event.occurred_at);
      return timestamp.toLocaleDateString('en-CA');
      },
      isSelected: function(){
        let currentEventId = this.$store.getters.getCurrentEventId;
        return currentEventId === this.event.id;
      }
    }
};
</script>

<style scoped>
  .selected{
    background-color: rgba(220, 220, 220, 0.3);
  }
</style>