<template>
  <div class="footer">
    <div class="columns">
      <div class="content column is-two-thirds">
        <p class="links">
          <strong>Emodissey</strong> <br />
          Emotional Dissonance and the Post-Truth Crisis <br />
          &ensp;
          <router-link class="footer-link" to="/browse">  Browse </router-link> 
          &ensp;
          <router-link class="footer-link" to="/search">  Search    </router-link>
          &ensp;
          <router-link class="footer-link" v-if="user.user_role !== 'watcher'" to="/upload">  Upload    </router-link>
          &ensp;
          <router-link class="footer-link" v-if="user.user_role === 'admin'" to="/administrative">  Administrative </router-link>
        </p>
        
       
      </div>
      <div class="content column ">
        <p>
          Principal Investigator:
          <a
            class="has-text-dark"
            target="_blank"
            href="https://faculty.arts.ubc.ca/bdancygier/"
          > <u>
            Barbara Dancygier.
            </u>
          </a>
        </p>
      </div>
    </div>
  </div>
  <!-- <footer class="footer" style="border-top: 0.5px solid black">
  <div class="content  has-text-centered">
    <p>
      <strong>Emodissey</strong>
    </p>
    <p> 
        Principal Investigator: <a class="has-text-dark" target="_blank" href="https://faculty.arts.ubc.ca/bdancygier/" > Barbara Dancygier. </a>
    </p>
  </div>
</footer> -->
</template>

<script>
export default {
  name: "Footer",
  computed: {
    user: function() {
      return this.$store.getters.getUser;
    }
  }
};
</script>

<style scoped>
.footer {
  border-top: 0.5px solid black;
  padding-top: 5em;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 200px;
}

.footer-link{
  color: dimgray;
  text-decoration: underline;
  margin-right: 10px;
  
}


.footer-link:hover{
  color:goldenrod;
}
</style>
