<template>
  <nav
    class="navbar is-size-4"
    role="navigation"
    aria-label="main navigation"
    style="border-bottom: solid 1px darkgrey;"
  >
    <div class="navbar-brand">
      <a class="navbar-item" href="/">
        <b> Emodissey </b>
      </a>
      <a
        role="button"
        class="navbar-burger burger"
        aria-label="menu"
        aria-expanded="false"
        data-target="navbarItems"
        onclick="document.querySelector('#navbarItems').classList.toggle('is-active')"
      >
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navbarItems" class="navbar-menu">
      <div class="navbar-start">
        <router-link class="navbar-item has-text-dark" to="/browse"
          >Browse</router-link
        >

        <router-link class="navbar-item has-text-dark" to="/search"
          >Search</router-link
        >

        <router-link
          v-if="user.user_role !== 'watcher'"
          class="navbar-item has-text-dark"
          to="/upload"
          >Upload</router-link
        >

        <router-link
          v-if="user.user_role === 'admin'"
          class="navbar-item has-text-dark"
          to="/administrative"
          >Administrative</router-link
        >
      </div>

      <div class="navbar-end">
        <div class="navbar-item">
          <div class="buttons">
            <a class="button is-light is-size-6" @click="toggleReport">
              report an issue
            </a>
            <a class="button is-light is-size-6" @click="toggleLogoutModal()">
              logout
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Logout Modal -->
    <div class="modal" :class="{ 'is-active': logoutModal }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Logout</p>
          <button class="delete" aria-label="close"></button>
        </header>
        <section class="modal-card-body">
          <!-- Content ... -->
          <p class="is-size-6" v-if="!logoutSuccess">
            Are you sure you want to logout?
          </p>
          <div
            class="notification is-success"
            v-if="logoutSuccess"
            style="color: #F4F6F6;
        background-color:#52BE80;"
          >
            Logout is successful.
          </div>
        </section>
        <footer class="modal-card-foot" v-if="!logoutSuccess">
          <button class="button is-success" @click="logout()">Yes</button>
          <button class="button" @click="toggleLogoutModal">No, cancel</button>
          <br />
        </footer>
      </div>
    </div>

    <!-- Report and issue modal -->
    <div class="modal" :class="{ 'is-active': reportModal }">
      <div class="modal-background" @click="toggleReport"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Report an issue</p>
          <button
            class="delete"
            aria-label="close"
            @click="toggleReport"
          ></button>
        </header>
        <section class="modal-card-body">
          <!-- Content ... -->
          <div class="field">
            <label class="label">Please describe the nature of the issue</label>
            <div class="control">
              <textarea
                class="textarea"
                placeholder="Describe the issue..."
                maxlength="1000"
                v-model="issueMessage"
              ></textarea>
               <p v-if="noIssueMessage" class="help is-danger">
                Please describe the issue.
              </p>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <div class="buttons" v-if="!reportError && !reportSuccess">
          <button
            class="button"
            @click="submitReport"
            :class="{'is-loading': sendingReport}"
          >
            Report
          </button>
          <button class="button" @click="toggleReport">Cancel</button>
          </div>
          <div
            v-if="reportSuccess"
            class="notification "
            style="color: #F4F6F6;
        background-color:#52BE80;"
          >
            The issue has been reported. Thank you.
          </div>
          <div
            v-if="reportError"
            class="notification is-danger"
            style="color: #F4F6F6;
        background-color:#E74C3C;"
          >
            Something went wrong. Please try again later.
          </div>
        </footer>
      </div>
    </div>
  </nav>
</template>

<script>
import axios from "axios";

export default {
  name: "NavigationBar",
  data() {
    return {
      logoutModal: false,
      isLoggingOut: false,
      logoutSuccess: false,
      reportModal: false,
      issueMessage: "",
      noIssueMessage: false,
      reportSuccess: false,
      reportError: false,
      sendingReport: false,
    };
  },
  computed: {
    user: function() {
      return this.$store.getters.getUser;
    }
  },
  methods: {
    toggleLogoutModal() {
      this.logoutModal = !this.logoutModal;
    },

    /**
     * Sends a logout request to the backend server.
     *
     * Sends a logout request to the backend server and shows a message to
     * the user in the case of success or failure of the request. Then the user
     * redirecte to the homepage after a second.
     */
    logout() {
      this.isLoggingOut = true;
      this.logoutSuccess = false;
      try {
        axios
          .get("/logout")
          .then(() => {
            this.isLoggingOut = false;
            this.logoutSuccess = true;
            this.$store.dispatch("setUser", "");
            setTimeout(() => {
              this.$router.push("/");
            }, 1000);
          })
          .catch(() => {
            this.isLoggingOut = false;
            this.$store.dispatch("setUser", "");
            setTimeout(() => {
              this.$router.push("/");
            }, 1000);
          });
      } catch(err) {
        // todo
      }
    },

    /**
     * Activates/deactivates the report an issue modal.
     */
    toggleReport() {
      this.reportModal = !this.reportModal;
    },

    /**
     * Builds and submits the issue report.
     *
     * Checks if the required issueMessage is provided by the user. Then
     * the issue message is sent to the backend along with the user_id and
     * username. In response the user is shown a notification for success or
     * failure. If user tries to submit a report without providing an input
     * then the form is not sent and the user is shown a message requesting them
     * to provide an input message describing the issue.
     *
     */
    submitReport() {
      if(this.issueMessage.length < 1) {
        this.noIssueMessage = true;
      }
      this.noIssueMessage = false;
      this.reportSuccess = false;
      this.reportError = false;
      this.sendingReport = true;
      
      let issueForm = this.getIssueForm();
      axios({
          method: 'post',
          url: '/report-issue',
          data: issueForm,
      })
      .then(() => {
        this.sendingReport = false;
        this.reportSuccess = true;
        setTimeout(() => {
          this.resetReportModal();
        }, 1000);
      })
      .catch(() => {
        this.sendingReport = false;
        this.reportError = true;
        setTimeout(() => {
          this.resetReportModal();
        }, 1000);
      });
    },

    /**
     * Builds and returns the issue form object formed by username,
     * issue_message and path_string.
     * 
     */
    getIssueForm(){
      let issueForm = {
        username: this.user.username,
        issue_message: this.issueMessage,
        path_string:  this.$route.path,
      };
      return issueForm;
    },

    /**
     * Resets all the fields and notifications of the report issue modal.
     */
    resetReportModal(){
      this.reportModal = false;
      this.issueMessage= "";
      this.noIssueMessage= false;
      this.reportSuccess= false;
      this.reportError= false;
      this.sendingReport= false;
    }
  }
};
</script>

<style scoped>
.navbar-item:hover {
  color: hsl(48, 100%, 67%);
}
nav{
  padding-bottom: 5px;
}
</style>
