<template>
  <div>
    <navigation-bar />
    <div class="section"> 
      <h1 class="is-size-4"> Upload a Response Event  >
           </h1>
       <h1 class="is-size-4"> Upload a Tweet via Url
           </h1>
   </div>
    <upload-tweet-link />
    <footer-component />
  </div>
</template>

<script>

import NavigationBar from "../../components/NavigationBar.vue";
import UploadResponseTweetLink from "../../components/Response/UploadResponseTweetLink";
import Footer from '../../components/Footer';


export default {
  name: "UploadResponseTweetLink",
  components: {
    "navigation-bar": NavigationBar,
    "upload-tweet-link": UploadResponseTweetLink,
    'footer-component': Footer,
  }
};
</script>
