<template>
  <div>
    <div class="section">
      <h1 class="is-size-4"> Upload a Trigger Event </h1>
      <div class="link-fields">
      <div class="columns is-multiline">
        <div class="column is-half">
          <div class="box link-box" style="max-width:40em;
          text-align:center; vertical-align:center;
          cursor: pointer;" @click="$router.push('/upload/trigger/file')">
              <p class="has-text-dark is-size-4">image, video or a pdf file</p>
          </div>
        
        </div>

        <div class="column is-half">
          <div class="box link-box" style="max-width:40em;
          text-align:center; vertical-align:center;
          cursor: pointer;" @click="$router.push('/upload/trigger/without-artifact')">
              <p class="has-text-dark is-size-4">upload an event without an artifact</p>
          </div>
        
        </div>

        
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadTrigger",
  
};
</script>


<style scoped>
  .link-box{
    width:90%;
    text-align:center; 
    display: inline-block;
    cursor: pointer;
    min-height: 10em;
    overflow: hidden;
  }
  .link-box:hover{
    background-color: #F4F6F6;
  }

  .link-fields{
  width: 80%;
  margin-left:  5%;
  margin-right: 5%;
  padding: 5%;
}
</style>