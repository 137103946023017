<template>
  <div class="upload-response-tweet">
    <!-- Search Field -->
    <div class="section">
      <div class="field" style="margin-right:20em">
        <div class="control">
          <label class="label">Enter keywords or hashtag to search </label>
          <input
            class="input is-info"
            type="text"
            placeholder="search on twitter e.g.: #covid-19, trump, ..."
            v-on:keyup.enter="requestTweets"
            v-model="searchInput"
          />
          <p>
            This will search only the tweets posted in the last 7 days. This
            search is not exhaustive according to Twitter. Use built-in search
            feature in <a href="https://twitter.com">twitter</a> for a more
            exhaustive search.
          </p>
        </div>
        <div class="control">
          <div class="buttons is-right">
            <button
              class="button is-info"
              v-bind:class="{ 'is-loading': isActive }"
              @click="requestTweets"
            >
              Search
            </button>
          </div>
        </div>
        <div class="control">
          <p v-if="noSearchInput" class="help is-danger">
            Please provide something to search.
          </p>
        </div>
      </div>
    </div>

    <div class="columns">
      <div class="column is-two-thirds">
        <!-- Result tweet cards column -->
        <!-- Tweet Cards -->
        <div class="section">
          <div
            class="box"
            style="margin-right: auto; max-width:50em;"
            v-for="tweet in tweets"
            :key="tweet.id"
          >
            <article class="media">
              <div class="media-content">
                <tweet-card
                  :id="tweet.id"
                  error-message="This tweet could not be loaded"
                  ><div class="spinner"></div
                ></tweet-card>
              </div>
              <div class="media-right">
                <div class="field">
                  <div class="control">
                    <label class="checkbox">
                      <input
                        type="checkbox"
                        v-model="selectedTweets"
                        :value="tweet"
                      />
                      Select
                    </label>
                  </div>
                </div>

                <div class="field">
                  <div class="control">
                    <button
                      class="button is-info"
                      @click="selectAndUpload(tweet)"
                    >
                      Upload this tweet
                    </button>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>

      <div class="column">
        <!-- Selected tweets view column. -->
        <div
          class="box"
          style="max-height:20em;  margin-right:5em; position: fixed; overflow: scroll;"
          v-if="selectedTweets.length > 0"
        >
          Selected Tweets: {{ selectedTweets.length }}

          <div class="control">
            <div class="buttons is-right">
              <button class="button" @click="clearSelected">Clear all</button>
              <button
                class="button is-info"
                @click="isUploadModalActive = true"
              >
                Upload all
              </button>
            </div>
          </div>

          <br />
          <ul>
            <li v-for="twt in selectedTweets" :key="twt.id">
              <p>
                <strong> {{ twt.user }} </strong>
              </p>
              <p>{{ twt.text }}</p>
              <br />
            </li>
          </ul>

          <div class="control">
            <div class="buttons is-right">
              <button class="button" @click="clearSelected">Clear all</button>
              <button
                class="button is-info"
                @click="isUploadModalActive = true"
              >
                Upload all
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Upload Tweets Modal -->
      <div class="modal is-active" v-if="isUploadModalActive">
        <div
          class="modal-background"
          style="background-color: aliceblue; opacity: 0.7;"
        ></div>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">Upload Tweets</p>
            <button
              class="delete"
              aria-label="close"
              @click="closeUploadModal"
            ></button>
          </header>
          <section class="modal-card-body">
            Number of tweets selected: {{ selectedTweets.length }}

            <!-- Form -->
            <div class="field">
              <label class="label is-medium">Description</label>
              <div class="control">
                <input
                  class="input"
                  type="text"
                  v-model="description"
                  placeholder="Enter a description for tweet(s)"
                  maxlength="1000"
                />
              </div>
              <!-- no description warning -->
              <p v-if="errorObject.description" class="help is-danger">
                {{ errorObject.description }}
              </p>
            </div>

            <!-- Trigger Input -->
            <div class="field">
              <label class="label is-medium">Select a trigger event</label>
              <div class="control">
                <div class="select is-medium">
                  <select v-model="selectedTrigger">
                    <option
                      v-for="trigger in triggerEvents"
                      :key="trigger.id"
                      :id="trigger.id"
                      :value="trigger.id"
                    >
                      {{ trigger.event_name }}
                    </option>
                  </select>
                </div>
              </div>
              <!-- no trigger warning -->
              <p v-if="errorObject.trigger" class="help is-danger">
                {{ errorObject.trigger }}
              </p>
            </div>

            <!-- Tags Input -->
            <div class="field " style="margin-bottom: 5em;">
              <label class="label is-medium">Select tags</label>
              <tags-input :tags="tags" @tags-changed="updateSelectedTagIds" />
              <!-- no tags warning -->
              <p v-if="errorObject.tags" class="help is-danger">
                {{ errorObject.tags }}
              </p>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button
              class="button is-info"
              :class="{ 'is-loading': submitting }"
              @click="uploadTweets"
            >
              Upload
            </button>
            <button class="button" @click="closeUploadModal">
              Cancel
            </button>
            <div
              v-if="success"
              class="notification "
              style="color: #F4F6F6;
        background-color:#52BE80;"
            >
              Tweet(s) have been submitted successfully.
            </div>
            <div
              v-if="error"
              class="notification is-danger"
              style="color: #F4F6F6;
        background-color:#E74C3C;"
            >
              Something went wrong. Please try again later or report it as an
              issue.
            </div>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { Tweet } from "vue-tweet-embed";
import TagsInput from "../TagsInput";

export default {
  name: "UploadResponseTweets",
  data() {
    return {
      isActive: false,
      searchInput: "",
      noSearchInput: false,
      tweets: [],
      errorMessage: "",
      selectedTweets: [],
      isUploadModalActive: false,
      description: "",
      selectedTrigger: "",
      selectedTriggerName: "",
      selectedTagIds: [],
      submitting: false,
      success: false,
      error: false,
      errorObject: {
        description: "",
        tags: "",
        trigger: "",
        tweets: ""
      }
    };
  },
  created() {
    this.$store.dispatch("requestTriggerEvents");
    this.$store.dispatch("requestTags");
  },
  computed: {
    triggerEvents: function() {
      return this.$store.getters.getTriggerEvents;
    },
    tags: function() {
      return this.$store.getters.getTags;
    }
  },
  methods: {
    // gets the tweets according to the searchInput
    requestTweets() {
      if (!this.searchInput) {
        this.noSearchInput = true;
        return;
      }
      this.tweets = [];
      this.noSearchInput = false;
      this.isActive = true;
      this.errorMessage = "";
      let url = "/tweets/" + encodeURIComponent(this.searchInput);
      axios
        .get(url)
        .then(response => {
          this.tweets = response.data;
          this.isActive = false;
        })
        .catch(() => {
          this.isActive = false;
          this.errorMessage =
            "Something went wrong getting the tweets. Please try again later or report an issue.";
        });
    },

    // clears the list of selected tweets
    clearSelected() {
      this.selectedTweets = [];
    },

    // returns the name of the selected trigger event given its id
    getTriggerName(id) {
      let triggerName = this.triggerEvents.find(trigger => trigger.id === id);
      return triggerName.event_name;
    },

    selectAndUpload(tweet) {
      this.selectedTweets = [];
      this.selectedTweets.push(tweet);
      this.isUploadModalActive = true;
    },

    // post tweets to the backend to upload
    uploadTweets() {
      if (this.checkFields()) {
        let formObject = this.getFormObject();
        this.submitting = true;
        axios({
          method: "post",
          url: "/upload-response",
          data: formObject
        })
          .then(() => {
            this.submitting = false;
            this.error = false;
            this.success = true;
            setTimeout(() => {
              this.$router.go();
            }, 1000);
          })
          .catch(() => {
            this.submitting = false;
            this.success = false;
            this.error = true;
          });
      }
    },
    checkFields() {
      let result = true;
      // tweets
      if (this.selectedTweets.length < 1) {
        this.errorObject.tweets = "Please select (a) tweet(s) to upload.";
        result = false;
      } else {
        this.errorObject.tweets = "";
      }
      // description
      if (this.description.length < 1) {
        this.errorObject.description = "Please provide a description.";
        result = false;
      } else {
        this.errorObject.description = "";
      }
      // trigger
      if (this.selectedTrigger.length < 1) {
        this.errorObject.trigger = "Please select a trigger event.";
        result = false;
      } else {
        this.errorObject.trigger = "";
      }
      return result;
    },
    getFormObject() {
      let formData = new FormData();
      let trigger_name = this.getTriggerName(this.selectedTrigger);
      let tweetEvents = [];
      for (let tweet of this.selectedTweets) {
        let tweetEvent = {
          event_name: tweet.text.substring(0, 240),
          description: this.description,
          event_type: "tweet",
          src: tweet.url,
          content: tweet.text,
          occurred_at: this.createDateString(tweet.created_at),
          author: tweet.user,
          trigger_id: this.selectedTrigger,
          trigger_name: trigger_name,
          external_id: tweet.id
        };
        tweetEvents.push(tweetEvent);
      }
      formData.append("events", JSON.stringify(tweetEvents));
      formData.append("tags", JSON.stringify(this.selectedTagIds));
      return formData;
    },
    closeUploadModal() {
      this.error = false;
      this.success = false;
      this.isUploadModalActive = false;
    },
    createDateString(dateString) {
      const date = new Date(dateString);
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getUTCFullYear();
      let formattedString = year + "-" + month + "-" + day;
      return formattedString;
    },
    replaceQuotes(text) {
      let regex = /"/gi;
      let result = text.replace(regex, "'");
      return result;
    },
    updateSelectedTagIds(idArray) {
      this.selectedTagIds = idArray;
    }
  },
  components: {
    "tweet-card": Tweet,
    "tags-input": TagsInput
  }
};
</script>

<style scoped>
.upload-response-tweet {
  margin-bottom: 5em;
}</style
>>
