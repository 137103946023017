<template>
  <div class="section search-form">
    <h1 class="is-size-4" style="margin-bottom: 1em;">Search</h1>
    <div class="search-fields">
      <div class="field">
        <label class="label is-size-4">Select trigger events</label>
        <div class="block">
          <!-- Checkboxes for trigger events -->
          <div
            class="checkboxElement"
            v-for="triggerEvent in triggerEvents"
            :key="triggerEvent.id"
          >
            <input
              type="checkbox"
              :id="triggerEvent.id"
              :value="triggerEvent.id"
              v-model="checkedTriggers"
            />
            <label class="is-size-6" for="triggerEvent.id">
              {{ triggerEvent.event_name }}</label
            >
          </div>
        </div>
      </div>

      <div class="field">
        <label class="label is-size-4">Select types</label>
        <div class="block">
          <!-- Checkboxes for types -->
          <div
            class="checkboxElement"
            v-for="type in types"
            :key="types.indexOf(type)"
          >
            <input
              type="checkbox"
              :id="type"
              :value="type"
              v-model="checkedTypes"
            />
            <label class="is-size-6" for="type">{{ type }}</label>
          </div>
        </div>
      </div>

      <!-- New Tags Input -->
      <div class="field is-size-4">
        <label class="label is-size-4">Select tags</label>
        <tags-input :tags="databaseTags" @tags-changed="updateSelectedTagIds" />
      </div>
      <br />
      <div class="field">
        <label class="label is-size-4">Enter time interval</label>

        <label class="label">Start</label>
        <!-- Date Input -->
        <div class="field">
          <functional-calendar
            class="date-picker"
            :is-date-picker="true"
            :change-month-function="true"
            :change-year-function="true"
            :dateFormat="'yyyy/mm/dd'"
            :is-modal="true"
            v-on:dayClicked="updateStartDate"
          >
          </functional-calendar>
        </div>

        <label class="label">End</label>
        <!-- Date Input -->
        <div class="field">
          <functional-calendar
            class="date-picker"
            :is-date-picker="true"
            :change-month-function="true"
            :change-year-function="true"
            :dateFormat="'yyyy/mm/dd'"
            :is-modal="true"
            v-on:dayClicked="updateEndDate"
          >
          </functional-calendar>
        </div>
      </div>

      <div class="field">
        <button class="button is-dark " @click="searchEvents()">Search</button>
      </div>
    </div>
  </div>
</template>

<script>
import TagsInput from "./TagsInput";
import { FunctionalCalendar } from "vue-functional-calendar";

export default {
  name: "SearchForm",
  components: {
    "tags-input": TagsInput,
    "functional-calendar": FunctionalCalendar,
  },
  data() {
    return {
      checkedTriggers: [],
      checkedTypes: [],
      startDate: "",
      endDate: "",
      types: ["pdf", "image", "video", "tweet", "youtube", "youtube-comment"],
      selectedTagIds: [],
    };
  },
  created() {
    this.$store.dispatch("requestTriggerEvents");
    this.$store.dispatch("requestTags");
  },
  computed: {
    triggerEvents: function() {
      return this.$store.getters.getTriggerEvents;
    },
    databaseTags: function() {
      return this.$store.getters.getTags;
    },
  },
  methods: {
    /**
     * Builds a query based on user input and then requests the events
     * with the query. Routes to the events view.
     */
    searchEvents: function() {
      let queryObject = this.createQueryObject();
      let queryString = this.createQueryString(queryObject);
      this.$router.push({
        name: "events",
        params: { queryString: queryString },
      });
    },

    /**
     * Creates a query object with properties triggers, tags, types, from, and to.
     */
    createQueryObject: function() {
      let triggers = "";
      let tags = "";
      let types = "";
      let from = "";
      let to = "";
      triggers = this.checkedTriggers.toString();
      tags = this.selectedTagIds.toString();
      types = this.checkedTypes.toString();
      from = this.startDate;
      to = this.endDate;

      return {
        triggers: triggers,
        types: types,
        tags: tags,
        from: from,
        to: to,
      };
    },

    /**
     * Creates a simply query string for search.
     *
     * Query keys are trigger, types, tags, from and to. Each key-values pair
     * are added to the query with & delimiter.
     */
    createQueryString(queryObject) {
      let queryArray = [];

      for (let queryKey of Object.keys(queryObject)) {
        let query = queryKey + "=" + queryObject[queryKey];
        queryArray.push(query);
      }

      return queryArray.join("&");
    },

    /**
     * Updates the tagId array with the given idArray
     */
    updateSelectedTagIds(idArray) {
      this.selectedTagIds = idArray;
    },

    // updates the selected event date based on date-picker event
    updateStartDate(newDate) {
      this.startDate = newDate.date.split("/").join("-");
    },

    updateEndDate(newDate) {
      this.endDate = newDate.date.split("/").join("-");
    },
  },
};
</script>

<style>
.checkboxElement {
  display: inline-block;
  margin: 0.5em;
}

.search-fields {
  border: solid gainsboro 1px;
  width: 80%;
  margin-left: 5%;
  margin-right: 5%;
  padding: 5%;
}
</style>
